import React from 'react'
import { BsCheck } from 'react-icons/bs';
import Select from "react-select";
import { customStyles } from '../../util/FormatUtil';
import PhoneInput from "react-phone-number-input";

const CustomPhone = (props) => {

    // console.log('props in CustomSelect', props)

    return (
        <div className="form-group row">
                <div className={props.fromFilterPage ? "col-2 pl-0" : "col-12 col-md-4"} data-toggle={"tooltip"} data-placement={"top"} title={props.tooltip}>
                    <label id={props.label} htmlFor={props.label} style={{ fontSize: "0.8em" }} className={"pt-1 font-weight-bold"}>
                    {props.label}
                    </label>
                </div>
                <div className={props.fromFilterPage ? "col-8 col-md-7 col-lg-5 pl-md-0" : "col-12 col-md-8 pl-md-0 pr-md-1"} id={props.label}>
                    <PhoneInput
                    id={props.label}
                    placeholder="Enter phone #"
                    onChange={(e) => {
                        props.handleDateOnChange(e)
                    }}
                    defaultCountry="US"
                    value={props.value}
                    aria-label={props.fromFilterPage ? props.label.replaceAll(' ', '') + "Filter" : props.label.replaceAll(' ', '')}
                    />
                </div>
                <div className="d-none d-lg-inline col-lg-2"></div>
                <div className={props.fromFilterPage ? "col-2 col-md-1" : "d-none"}>
                    {props.value || props.nullValue ? (<BsCheck className={"float-right"} color={"#21212E"} size={20} />) : null}
                </div>
                <div className={props.fromFilterPage ? "col-12 col-md-10 pl-0 pr-1" : "d-none"}>
                    <div className="row mt-1">
                        <div className="col-12">
                            <Select
                            styles={customStyles}
                            isMulti={true}
                            isSearchable={true}
                            placeholder={<div className="accessibilityText">Select...</div>}
                            aria-label={props.fromFilterPage ? props.label.replaceAll(' ', '') + "Filter" : props.label.replaceAll(' ', '')}
                            onChange={(e) => {
                                props.handleChange(e)
                            }}
                            className={"state_select"}
                            options={props.options}
                            value={props.nullValue}
                            />
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default CustomPhone