import moment from "moment";
import domain from "../Domain";
import {CustomFieldTypesObj} from "../types/CustomFieldType";
import { sweetalert } from "../App";

export const getBrandingImage = async (name:string) =>{
    let res = await fetch(domain + "/api/system/image", {method:"POST",   headers: {'Content-Type': 'application/json'}, body:JSON.stringify({Name:name})});
    let obj = await res.json();
    return obj.Link;
}

// Takes a DATE 2019-01-30 -> 01-30-2019
export const formatUSDate = (x, splitter?) =>{
    if(!x)
        return "";
    x = x.split(splitter ? splitter : '-');
    return x[1] + "-" + x[2] + "-" + x[0];
};

export const formatDateFromMMDDYYYYtoYYYYMMDD = (dateString) => {
    var dateParts = dateString.split("-");
    return new Date(dateParts[2], dateParts[0] - 1, dateParts[1].toISOString().split('T')[0]);
}

export const getExemptImmunizations = (val, immunizations) => {
    if(val.length < 2){
        const foundImmunizations = immunizations.find(obj => obj.value === parseInt(val))
        return foundImmunizations.label;
    } else if(val.length >= 2){
        const valArray = val.split(',').map(Number);
        const foundImmunizations = immunizations.filter(obj => valArray.includes(obj.value));
        const resultString = foundImmunizations.map(obj => obj.label).join(', ');
        return resultString;
    }
}

// Takes a DATE 2019-01-30T00:00:00 -> 2019-01-30
export const parseDate = (x:string | Date, noFormat?:boolean):string =>{


    if(!x)
        return "";
    if(x instanceof Date)
        x = x.toISOString()


    x = x.slice(0, 10);
    return !noFormat ? formatUSDate(x) : x;
};

export const getTwelveHourAMPMTime = (x:string) => {
    if(!x)
        return "";
    return moment(x, "hh:mm").format('LT')
}

export const toLocalDateTime = (x:string):string =>{
    if(!x){
        return "";
    }
    let utcMoment = moment.utc(x).toDate();
    return moment(utcMoment).local().format('MM-DD-YYYY h:mm a');
};


export function getFormattedDBTime(time:string){
    return moment(time, 'HH:mm:ss').format('HH:mm');
}
export const toLocalDate = (x:string):string =>{
    let utcMoment = moment.utc(x, 'YYYY-MM-DD hh:mm:ss.SSSSSSS').format('YYYY-MM-DD HH:mm:ss.SSS');
    return moment.utc(utcMoment).format('YYYY-MM-DD hh:mm a');
};


export const hideModal = (id:string)=>{
    window['$']("#" +  id).modal('hide');
}

export const showModalNoOutsideClick = (id:string) => {
    window["$"]("#"+id).modal({
        backdrop: "static", keyboard:false
    });
}

export const toReactSelectList = (arr:string[]) =>{
    return arr.map(s => ({label:s, value:s}))
};

export const formatMultiselect = (data, label_key, value_key) =>{
    return data.map(d =>{
        return {label:d[label_key], value:d[value_key]}
    })
};

export const getPageOptions = (n:number) =>{
    let options = []
    for (let i = 1; i <= n; i++) {
        options.push({value: i, label: i})
    }
    return options
};

export const getReactSelectAriaLabel = (label:string, value:{label:string, value:any}, isFilter=false) =>{
    return  value ? `${label}${isFilter ? ` Search` : ``} Option ${value.label} is selected` : `${label}${isFilter ? ` Search` : ``}`
}

export const getReactSelectAriaLabelMultiselect = (label:string, values:[{label:string, value:number}], isFilter=false) =>{
    return  values?.length > 0 ? `${label}${isFilter ? ` Search` : ``} Option ${values?.map((obj) => obj.label)} is selected` : `${label}${isFilter ? ` Search` : ``}`
}

export const getDateOrNull = (val) => {
    return val ? moment(val, ['MM/DD/YYYY', 'MM-DD-YYYY', 'MM/DD/YYYY h:mm a', 'MM-DD-YYYY h:mm a'], true) : null;
}

export const buildRow = (label, content, tooltip='', showRequired=false) => {
    return (
        <div className="form-group row" data-toggle={'tooltip'} data-placement={'top'} title={tooltip}>
            <label id={label.replaceAll(' ', '')}  htmlFor={label.replaceAll(' ', '')} className="col-12 col-sm-4 col-form-label text-center text-md-left">{label}{showRequired ? <section className="text-danger d-inline-block px-1">*</section> : ''}</label>
            <div aria-labelledby={label.replaceAll(' ', '')} className="col-12 col-sm-8 p-0 m-0 text-center text-md-left" tabIndex={content.props.isDisabled ? 0 : -1} >
                {content}
            </div>
        </div>
    )
}

export function getNumPages (rows:any[], itemsPerPage) {
    return rows.length % itemsPerPage > 0 ? rows.length / itemsPerPage + 1 : rows.length / itemsPerPage
}

export function slicePages (rows, page, itemsPerPage) {
    page = page ? page : 1
    return rows.slice(itemsPerPage * (page - 1), itemsPerPage * page)
}

export function getYesNoUnknownOptions() {
    return [
      { value: "Yes", label: "Yes" },
      { value: "No", label: "No" },
      { value: "Unknown", label: "Unknown" },
    ];
}

export function getEmailReportToOptions() {
    return [
      { label: "Patient Only", value: 0 },
      { label: "Provider Only", value: 1 },
      { label: "Patient and Provider", value: 2 },
    ];
}

export const getEmailReportToVal = (val) => {
    return val === 2 ? {label: "Patient and Provider", value: 2} : val === 1 ? {label: "Provider Only", value: 1} : {label: "Patient Only", value: 0};
}

export const getBoolSelectVal = (val) => {
    return val === 1 || val === true ? {label:"Yes", value:true} : {label:"No", value:false}
}

export const getBoolSelectYN = (val) => {
    return val === 1 || val === true ? 'Yes' : 'No';
}

export const getBoolOptionsWithNullString = () => {
    return [
        { value: 1, label: "Yes" },
        { value: "*null", label: "No" },
    ];
}

export const getBoolOptionsWithNullValue = () => {
    return [
      { value: 1, label: "Yes" },
      { value: null, label: "No" },
    ];
  }

export const getNullOptions = () => {
    return [
        { label: "No Data", value: "*null", color: '#C31230', fontWeight: 'bold' },
        { label: "Has Data", value: "<>null", color: '#21212E', fontWeight: 'bold' },
    ];
  }

export const getFieldTypeVal = (val) => {
    let temp;
    for (const [key,value] of Object.entries(CustomFieldTypesObj)){
        if(val === Number(value)){
            temp = {label: key, value: value};
        }
    }
    return temp;
}

export const getFieldTypeLabel = (val) => {
    let temp;
    for (const [key,value] of Object.entries(CustomFieldTypesObj)){
        if(val === Number(value)){
            temp = {label: key, value: value};
        }
    }
    return temp.label;
}

export const getBoolSelectOptions = () => {
    return [
        { label: "Yes", value: true },
        { label: "No", value: false }
    ]
}

export const getLabelWhenLabelAndValueAreSame = (label, array) => {
    if(!array){return}
    let temp = array.find(t => t.value === label)
    if(temp ===undefined){
        return null;
    }
    return {label: temp.label, value: temp.label}
}

export const getFacilityLabel = (val, propArray) => {
    let temp = propArray.find(s => s.value === val || s.label === val)
    if(!temp){
        return null;
    }
    return {label: temp.label, value: val}
}

export const getLabel = (id, searchArray) => {
    if(!searchArray){ return }
    let temp = searchArray.find(t => t.value === id)
    if(temp===undefined){
        return null;
    }
    return {label: temp.label, value: id};
}

export const getLabelForDocument = (id, searchArray) => {
    if(!searchArray || searchArray.length < 1){
        return
    }
    let temp = searchArray.find(t => t.ID === id)
    if(temp===undefined){
        return null;
    }
    return {label: temp.DocumentName, value: id}
}

export const getLabelForBinAndBatch = (id, searchArray) => {
    if(!searchArray){ return }
    let temp = searchArray.find(t => t.value.ID === id)
    if(temp===undefined){
        return null;
    }
    return {label: temp.label, value: temp.value};
}

export const getLabelsForMultiSelect = (values, searchArray) => {
    if(values?.length < 2){
        return [getLabel(parseInt(values), searchArray)]
    } else if(values?.length >= 2) {
        let labelArray = [];
        
        for(let val of values){
        labelArray.push(getLabel(parseInt(val), searchArray));
        }
        return labelArray;

    }
}


export const getSelectLabels = (array) => {
    let temp = [];
    array.map(t => {
        if(t.Name){
            temp.push({label: t.Name, value: t.ID})
        }
        else if(t.FacilityName){
            temp.push({label: t.FacilityName, value: t.ID})

        }
    })
    return temp;
}

export const getLabelFromName = (id, array) => {
    let temp = array.find(f => f.ID === id);
    return temp ? {label:temp.Name, value:temp.ID} : null
}


export const createCustomFieldDataArray = (obj) => {
    let tempArray = [];
    for (const [key,value] of Object.entries(obj)) {
        tempArray.push({label: key, value: value})
    }
    return tempArray;
}

export const getCustomFieldDataSelectLabel = (data, question) => {
    let temp;

    if(Array.isArray(data)){
        temp = [];
        question.Options.options.map(q => {
            data.map(d => {
                if(d === q.value){
                    temp.push({label: q.label, value: d})
                }
            })
        })
    }
    else{
        temp = question.Options.options.find(q => q.value === data)
    }

    return temp;
}

export const getValueArray = (e) => {
    let temp = [];
    e && e.map(s => {
        temp.push(s.value)
    })
    return temp;
}

export const getValidPageValues = (validPages) => {
    let temp=[];
    validPages.forEach(v => {
           temp.push(v.value)
    })
    return temp;
}

export const isSuperSet = (set, subset) => {
    for(const elem of subset){
        if(!set.has(elem)){
            return false;
        }
    }
    return true
}

export const getServiceName = (questionsArray, allServicesArray) => {
    let serviceName
    if(questionsArray.length > 0){
        let serviceID = questionsArray[0].ServiceID
        serviceName = allServicesArray.find(a => a.ID === serviceID)
        return serviceName.Name
    }
}

export const getServiceReportType = (id, array) => {
    if(array.length > 0){
        let service = array.find(f => f.ID === id)
        return service.ReportType
    }
}

export const getNameFromID = (id, array) => {
    if((!id || id === 'undefined') && id !== 0){
        return '';
    }

    let obj = array.find(a => a.ID || a.ID === 0 ? a.ID === id : a.value ? a.value === id : null )

    if(obj){
        return obj.FacilityName ? obj.FacilityName : obj.BatchNum ? obj.BatchNum : obj.Name ? obj.Name : obj.Value ? obj.Value : obj.label ? obj.label : obj.value ? obj.value : ''     
    }
    else{
        return null
    }
}

export const getNamesFromIDs = (idsArray, sourceArray) => {
    let names = []
    if(idsArray?.length > 0 && sourceArray?.length > 0)
    idsArray.map(id => {
        let obj = sourceArray.find(f => f.ID === id)
        if(obj.Name){
            names.push(obj.Name)
        }
        else if(obj.Value){
            names.push(obj.Value)
        }
    })
    else{
     return null
    }
    
    return names?.length > 0 ? names : null

}

export const getlabelsFromIDs = (idsArray, sourceArray) => {
    let labels = []
    if(idsArray?.length > 0 && sourceArray?.length > 0)
    idsArray.map(id => {
        let obj = sourceArray.find(f => f.ID === id || f.value === id)
        if(obj && obj.Name){
            labels.push({label: obj.Name, value: obj.ID})
        }
        else if(obj && obj.FacilityName){
            labels.push({label: obj.FacilityName, value: obj.ID})
        }
        else if(obj && obj.Value){
            labels.push({label: obj.Value, value: obj.ID})
        }
        else if(obj && obj.label){
            labels.push({label: obj.label, value: obj.value})
        }
    })
    else{
     return null
    }
    
    return labels?.length > 0 ? labels : null

}

// export const getRapidTestNameFromID = (id, allTestsArray) => {
//     let test = allTestsArray.find(a => a.ID === id && !a.IsLabTest)
//     return test?.Name
// }



export const handleCloseModal = (modalID) => {
    sweetalert.fire({
        title: 'Are you sure you want to close without saving?',
        text: 'Changes may not be saved.',
        showCloseButton: false,
        showDenyButton: true,
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: 'Yes, close',
        denyButtonText: `Go Back`,
    }).then((result) => {
        if (result.isConfirmed){
            hideModal(modalID)
        }
        else if(result.isDenied) {
           return
        }
    })
}

export const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1)
}


export const handleSpecimenIDsOrLots = (e, specimenIDsOrLot, selectedTest, key, fromScan=false) => {
        let indexOfTestBeingChanged = -1;
        if(specimenIDsOrLot?.length > 0){
            for (let i = 0; i < specimenIDsOrLot.length; i++) {
                //loop through and find the one to change
                //if found splice and change it 

                if(specimenIDsOrLot[i].testID === selectedTest.ID){
                    indexOfTestBeingChanged = i
                    break
                }
            }
            if(fromScan){
                if(indexOfTestBeingChanged === -1 && e.trim().length > 0){
                    specimenIDsOrLot.push({[key]: e, testID: selectedTest.ID})
                }
                else{
                    if(e.trim().length > 0){
                        specimenIDsOrLot.splice(indexOfTestBeingChanged, 1, {[key]: e, testID: selectedTest.ID})
                    }
                    else{
                        specimenIDsOrLot.splice(indexOfTestBeingChanged, 1)
                    }
                }
            }
            else{
                if(indexOfTestBeingChanged === -1 && e.target.value.trim().length > 0){
                    specimenIDsOrLot.push({[key]: e.target.value, testID: selectedTest.ID})
                }
                else{
                    if(e.target.value.trim().length > 0){
                        specimenIDsOrLot.splice(indexOfTestBeingChanged, 1, {[key]: e.target.value, testID: selectedTest.ID})
                    }
                    else{
                        specimenIDsOrLot.splice(indexOfTestBeingChanged, 1)
                    }
                }
            }
        }
        else{
            if(fromScan){
                if(e.trim().length > 0){
                    specimenIDsOrLot.push({[key]: e, testID: selectedTest.ID})
                }
            }
            else{
                if(e.target.value.trim().length > 0){
                    specimenIDsOrLot.push({[key]: e.target.value, testID: selectedTest.ID})
                }
            }
        }
        return specimenIDsOrLot;
}

export const handleTestsForWalkinAndCheckin = (e, specimenIDs, lots) => {


    let testsArray = []
    e?.map(t => {
        testsArray.push(t.value)
    })

    
    for (let i = 0; i < specimenIDs.length; i++) {
        let notIncluded = !testsArray.includes(specimenIDs[i].testID)
        if(notIncluded){
            specimenIDs.splice(i, 1)
        }
    }

    for (let i = 0; i < lots.length; i++) {
        let notIncluded = !testsArray.includes(lots[i].testID)
        if(notIncluded){
            lots.splice(i, 1)
        }
    }

    return {testsArray: testsArray, specimenIDs: specimenIDs, lots: lots}                  
}

export const prepareMultiSelect = (data) => {
    let tempArr = [];
    data.map((f) => {
      return tempArr.push(f.value);
    });
    return tempArr;
}

export const   addDashesInNextProps = (ssn) => {
    let temp = ssn.slice(0, 3) + "-" + ssn.slice(3, 5) + "-" + ssn.slice(5);
    return temp;
  }

export const customStyles = {
    control: (styles) => ({ ...styles}),
    option: (styles, { data }) => {
        return { ...styles, color: data.color, fontWeight: data.fontWeight}
    },
    menu: provided => ({ ...provided, zIndex: 9999 })
};

export const removeNull = (array) => {
    return array.filter((f) => f.value !== "*null" && f.value !== "<>null")
}

export const getUnwrappedSelect = (e) => {
    if(!e) {
        e = [];
        return e;
    }

    let temp = [];

    if(Array.isArray(e)){
        e.map(b => {
            if(typeof b.value === 'object'){
                temp.push(b.value.ID)
            }
            else{
                temp.push(b.value)
            }
        })
    }
    else{
        temp = e.value.ID
    }
    return temp

}

export const getLabelIDsArray = (array) => {
    let idsArray = [];
    if(Array.isArray(array)){
        array.map(t => {
            idsArray.push(t.value);
        })
    }
    else{
        idsArray.push(array.value)
    }
    return idsArray;
}

export const formatPhone = (phoneNumber) => {
    // Remove any non-digit characters from the phone number
    const digitsOnly = phoneNumber.replace(/\D/g, "");

    // Extract the different parts of the phone number
    const countryCode = digitsOnly.substring(0, 1);
    const areaCode = digitsOnly.substring(1, 4);
    const firstPart = digitsOnly.substring(4, 7);
    const secondPart = digitsOnly.substring(7, 11);

    // Format the phone number
    const formattedPhoneNumber = `1 (${areaCode}) ${firstPart}-${secondPart}`;

    return formattedPhoneNumber;
}

export const arraysHaveSameValues = (array1, array2) => {
    if (array1.length !== array2.length) {
      return false;
    }
  
    const sortedArray1 = array1.slice().sort();
    const sortedArray2 = array2.slice().sort();
  
    for (let i = 0; i < sortedArray1.length; i++) {
      if (sortedArray1[i] !== sortedArray2[i]) {
        return false;
      }
    }
  
    return true;
  }

export const getNextWellSlot = (wellslot, array) => {
    let nextWellSlotIndex = array.findIndex(obj => obj.WellSlot === wellslot)
    let nextWellSlot = array[nextWellSlotIndex + 1].WellSlot
    return nextWellSlot
}

export const wellSlots = [
    {label: 'Select...', value: null},
    {label: 'A1', value: 'A1'},
    {label: 'A2', value: 'A2'},
    {label: 'A3', value: 'A3'},
    {label: 'A4', value: 'A4'},
    {label: 'A5', value: 'A5'},
    {label: 'A6', value: 'A6'},
    {label: 'A7', value: 'A7'},
    {label: 'A8', value: 'A8'},
    {label: 'A9', value: 'A9'},
    {label: 'A10', value: 'A10'},
    {label: 'A11', value: 'A11'},
    {label: 'A12', value: 'A12'},
    {label: 'B1', value: 'B1'},
    {label: 'B2', value: 'B2'},
    {label: 'B3', value: 'B3'},
    {label: 'B4', value: 'B4'},
    {label: 'B5', value: 'B5'},
    {label: 'B6', value: 'B6'},
    {label: 'B7', value: 'B7'},
    {label: 'B8', value: 'B8'},
    {label: 'B9', value: 'B9'},
    {label: 'B10', value: 'B10'},
    {label: 'B11', value: 'B11'},
    {label: 'B12', value: 'B12'},
    {label: 'C1', value: 'C1'},
    {label: 'C2', value: 'C2'},
    {label: 'C3', value: 'C3'},
    {label: 'C4', value: 'C4'},
    {label: 'C5', value: 'C5'},
    {label: 'C6', value: 'C6'},
    {label: 'C7', value: 'C7'},
    {label: 'C8', value: 'C8'},
    {label: 'C9', value: 'C9'},
    {label: 'C10', value: 'C10'},
    {label: 'C11', value: 'C11'},
    {label: 'C12', value: 'C12'},
    {label: 'D1', value: 'D1'},
    {label: 'D2', value: 'D2'},
    {label: 'D3', value: 'D3'},
    {label: 'D4', value: 'D4'},
    {label: 'D5', value: 'D5'},
    {label: 'D6', value: 'D6'},
    {label: 'D7', value: 'D7'},
    {label: 'D8', value: 'D8'},
    {label: 'D9', value: 'D9'},
    {label: 'D10', value: 'D10'},
    {label: 'D11', value: 'D11'},
    {label: 'D12', value: 'D12'},
    {label: 'E1', value: 'E1'},
    {label: 'E2', value: 'E2'},
    {label: 'E3', value: 'E3'},
    {label: 'E4', value: 'E4'},
    {label: 'E5', value: 'E5'},
    {label: 'E6', value: 'E6'},
    {label: 'E7', value: 'E7'},
    {label: 'E8', value: 'E8'},
    {label: 'E9', value: 'E9'},
    {label: 'E10', value: 'E10'},
    {label: 'E11', value: 'E11'},
    {label: 'E12', value: 'E12'},
    {label: 'F1', value: 'F1'},
    {label: 'F2', value: 'F2'},
    {label: 'F3', value: 'F3'},
    {label: 'F4', value: 'F4'},
    {label: 'F5', value: 'F5'},
    {label: 'F6', value: 'F6'},
    {label: 'F7', value: 'F7'},
    {label: 'F8', value: 'F8'},
    {label: 'F9', value: 'F9'},
    {label: 'F10', value: 'F10'},
    {label: 'F11', value: 'F11'},
    {label: 'F12', value: 'F12'},
    {label: 'G1', value: 'G1'},
    {label: 'G2', value: 'G2'},
    {label: 'G3', value: 'G3'},
    {label: 'G4', value: 'G4'},
    {label: 'G5', value: 'G5'},
    {label: 'G6', value: 'G6'},
    {label: 'G7', value: 'G7'},
    {label: 'G8', value: 'G8'},
    {label: 'G9', value: 'G9'},
    {label: 'G10', value: 'G10'},
    {label: 'G11', value: 'G11'},
    {label: 'G12', value: 'G12'},
    {label: 'H1', value: 'H1'},
    {label: 'H2', value: 'H2'},
    {label: 'H3', value: 'H3'},
    {label: 'H4', value: 'H4'},
    {label: 'H5', value: 'H5'},
    {label: 'H6', value: 'H6'},
    {label: 'H7', value: 'H7'},
    {label: 'H8', value: 'H8'},
    {label: 'H9', value: 'H9'},
    {label: 'H10', value: 'H10'},
    {label: 'H11', value: 'H11'},
    {label: 'H12', value: 'H12'},
]

export const isEighteenOrOlder = (birthdate) => {
    const currentDate = new Date();
    const birthDate = new Date(birthdate);

    // Calculate the difference in years
    let age = currentDate.getFullYear() - birthDate.getFullYear();

    // Check if the birthdate has occurred on or before the same day of the year
    // If not, subtract one year from the age
    if (
        currentDate.getMonth() < birthDate.getMonth() ||
        (currentDate.getMonth() === birthDate.getMonth() &&
        currentDate.getDate() < birthDate.getDate())
    ) {
        age--;
    }

    // Check if the calculated age is 18 or older
    return age >= 18;
}