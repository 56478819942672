import React from "react";

interface TableBaseProps{
    table_style?:string
    colCount?:any
    rowCount?:any
}

export interface SimpleTableComponentProps{
    columns:Column[]
    table_data?:any[]
    evenWidthHeaders?:boolean
    rowBuilder?:(table_data:any[], columns:Column[]) => void
    columnClickedCallback?:(column:Column) => void
    
}

export interface Column{
    label:string
    key:string
    popoverText?:string
    override?:any
    raw?:string
    rawFormat?:(val:any) => any;
    formatFunc?:(val: any)=>string
    onClick?:(...any)=>any
    headerOnClick?:(thisColumn?:Column)=>any
    popover?:(...any)=>any
    modal_data?:any
    modal_key?:string
    isOwnModalData?:boolean
    isBreakPoint?:boolean
    content_class?:string
    extraCol?:Column[]
    direction?:string
}
export default class TableBase extends React.Component<TableBaseProps, {}>{
    constructor(props){
        super(props)
    }

    render(): React.ReactElement | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (
            <table aria-colcount={this.props.colCount} role="grid" className={"table " + (this.props.table_style ? this.props.table_style : " p-0 m-0")}>
                {this.props.children}
            </table>
        )
    }
}