import React from "react";
import 'react-quill/dist/quill.snow.css';
import Select from "react-select";
import TestType from "../../types/TestType";
import Overlay from "../Overlay";
import {
    getNumPages, getPageOptions, hideModal,
    showModalNoOutsideClick,
    slicePages,
    getLabel
} from "../../util/FormatUtil";
import { sweetalert } from '../../App';
import AdminAPI from "../../network/AdminAPI";
import FilterCard, { FIELD_TYPE, ReactSelect } from "../FilterCard";
import NetworkUtil from "../../network/NetworkUtil";
import { Column } from "../tables/TableBase";
import { Sorter, TableOrder } from "../../sorting/Sorter";
import SimpleTable from "../tables/SimpleTable";
import InBetweenOverlay from "../InBetweenOverlay";
import { UserContext } from "../../types/UserContext";
import { getIdToken } from "firebase/auth";
import ExemptionAPI from "../../network/ExemptionAPI";
import PhysicianExemptionRecordModal from "../modals/PhyicianExemptionRecordModal";
import moment from "moment";


const ITEMS_PER_PAGE = 25;

interface PhysicianHomeState {
    selectedTest?;
    tests: TestType[]
    testDescription?
    name?
    shortName?
    isEnabled?: { label, value }
    showLoading?
    showInBetween?
    resultTypes: { label, value }[]
    selectedExemption?
    allResults
    totalExemptions: number,
    exemptionQueryResults: []
    specimenSources: { label, value }[]
    searched?
    filteredObj?
    user?
    pageCustomQuestions?
    filter?: {
        FirstName,
        LastName,
        DOB,
        ReqNum
    }
    tableData: {}[]
    direction?: TableOrder
    page_options: ReactSelect[]
    selected_page?: { label, value }
    testQueryResults: TestType[]
    statusOptions: { label, value }[]
}
export interface PostedTest extends TestType {
    ResultIDs: number[]
    SpecimenIDs: number[]
}

export class PhysicianHomePage extends React.Component<any, PhysicianHomeState> {

    constructor(props) {
        super(props);
        this.state = {
            showLoading: false,
            showInBetween: false,
            selectedTest: {} as TestType,
            tests: [],
            totalExemptions: 0,
            selectedExemption: {},
            resultTypes: [],
            allResults: [],
            specimenSources: [],
            pageCustomQuestions: [],
            filter: { FirstName: "", LastName: "", DOB: "", ReqNum: "" },
            tableData: [] as any,
            direction: 'asc',
            page_options: [{ label: "1", value: "1" }],
            selected_page: { label: "1", value: 1 },
            testQueryResults: [] as any,
            exemptionQueryResults: [] as any,
            statusOptions: [{ label: "Pending", value: null }, { label: "Approved", value: 1 }, { label: "Denied", value: 0 }],
        }

    }


    componentDidMount() {
        document.title = 'Physician Home Page';
        this.queryExemptions(1)
    }

    async checkAuth() {
        if (!this.context) {
            sweetalert.fire({
                title: 'Error',
                text: 'You appear to be logged out. Please log in to access the application.',
                icon: 'error',
                confirmButtonText: 'OK'
            }).then(() => {
                window['location'] = '/login' as unknown as Location
            });
            return
        }

        try {
            let idToken = await getIdToken(this.context, true);
            const userCredential = await AdminAPI.getAuthenticated({ token: idToken });

            // console.log('userCredential', userCredential)

            let userProfile: UserContext = userCredential?.user;
            let userInitials = userProfile.FirstName[0] + userProfile.LastName[0];
            document.body.style.backgroundColor = 'white';
            this.setState({
                user: userCredential.user,
            })
        } catch (checkAuthError) {
            console.error(checkAuthError)

            sweetalert.fire({
                title: 'Error',
                text: 'Error verifying token. Please log in again.',
                icon: 'error',
                confirmButtonText: 'OK'
            }).then(() => {
                window['location'] = '/login' as unknown as Location;
            })
        }
    }

    queryExemptions(page: number) {
        this.setState({ showLoading: true }, async () => {
            let results = await ExemptionAPI.getFilteredPhysicianExemptions(page, { filter: this.state.filter })
            if (results.totalExemptions < 1) {
                sweetalert.fire({
                    icon: "error",
                    title: "",
                    text: "No records found",
                });
                this.setState({ showLoading: false, tableData: [], totalExemptions: 0 });
                return;
            }

            let sorter = new Sorter<any>();

            this.setState({
                tableData: slicePages(sorter.sortByKey(
                    results.table_data,
                    "CreatedDate" as keyof any,
                    "asc"
                ), 1, ITEMS_PER_PAGE),
                direction: this.state.direction === "asc" ? "desc" : "asc",
            });

            this.setState({
                totalExemptions: results.totalExemptions,
                searched: this.state.filteredObj,
                page_options: getPageOptions(getNumPages(results.table_data, ITEMS_PER_PAGE)),
                showLoading: false,
                exemptionQueryResults: results.table_data,
            });
        })
    }

    handleExportToCSV() {
        this.setState({ showLoading: true }, async () => {
            await NetworkUtil.downloadCSV(
                "/api/admin/tests/csv",
                "Test Management Data.xlsx",
                { filter: this.state.filter }
            )
            this.setState({ showLoading: false })
        });
    }

    useSorter(col: Column) {
        let sorter = new Sorter<any>()

        this.setState({ tableData: sorter.sortByKey(this.state.tableData, col.key as keyof any, this.state.direction), direction: this.state.direction === 'asc' ? 'desc' : 'asc' });
    }

    changePage(page: number) {
        let allExemptions = this.state.exemptionQueryResults;
        let returnData = slicePages(allExemptions, page, ITEMS_PER_PAGE);
        this.setState({ tableData: returnData });
    }

    renderTestMgmtFilterFields() {
        return (
            <FilterCard fields={[
                {
                    label: "Confirmation #",
                    "key": "ReqNum",
                    type: FIELD_TYPE.TEXT,
                },
                {
                    label: "Status",
                    "key": "PhysicianStatus",
                    type: FIELD_TYPE.SELECT,
                    options: [{ label: "Pending", value: "*null" }, { label: "Approved", value: "1" }, { label: "Denied", value: "0" }],
                    isMulti: true,
                    isMapped: true,
                },
                {
                    label: "Child First Name",
                    "key": "ChildFirstName",
                    type: FIELD_TYPE.TEXT,
                },
                {
                    label: "Child Last Name",
                    "key": "ChildLastName",
                    type: FIELD_TYPE.TEXT,
                },
                {
                    label: "Date of Birth",
                    key: "ChildDOB",
                    type: FIELD_TYPE.DATE,
                },
            ]} filterChanged={(e) => this.setState({ filter: e })} />
        )
    }

    render() {

        // console.log('TestMgmt state', this.state)

        return (<React.Fragment>
            <Overlay show_loading={this.state.showLoading} />
            <InBetweenOverlay showInBetween={this.state.showInBetween} />
            <PhysicianExemptionRecordModal
                record={this.state.selectedExemption}
                onClose={() => this.setState({ selectedExemption: {} })}
                onSubmit={() => { hideModal(PhysicianExemptionRecordModal.ID); this.queryExemptions(1) }}
            />
            <div className="container-fluid ">
                <div className={"row"}>
                    <div className="col-12 col-lg-8 col-xl-5 pt-2">
                        <main id="main-content" tabIndex={-1} aria-label="Test Management">
                            <div className="card mb-2">
                                <div className="card-header verlag-bold">
                                    <h4>Exemption Review</h4>
                                </div>
                                <div className="card-body">
                                    {this.renderTestMgmtFilterFields()}
                                </div>
                                <div className="card-footer">
                                    <button className={"btn immySubmitButtonOutline"}
                                        onClick={() => {
                                            this.setState({
                                                selected_page: { label: 1, value: 1 }
                                            }, () => {
                                                this.queryExemptions(this.state.selected_page.value)
                                            })
                                        }}
                                    >Search</button>
                                </div>
                            </div>
                        </main>
                    </div>


                    {
                        this.state.tableData &&
                        this.state.tableData.length > 0 &&

                        <div className="col-md-12 pt-2">
                            <div className="card mt-2 mb-5">
                                <div className="card-header verlag-bold">
                                    <h4 className="text-center text-md-left d-inline-block mr-3">Pending Exemptions</h4>
                                    <p className="text-danger m-0 d-inline-block">*Click on a Confirmation # to open and review an exemption</p>
                                    <section className="tableHeaderSection float-md-right d-flex justify-content-around">
                                        <h4 className={'float-md-right'} aria-label="Total Records" role="alert">Total: {this.state.totalExemptions}</h4>
                                        <h4 className="float-right align-middle pr-2 ml-5">Page </h4>
                                        <div className=" align-middle float-right pages ">
                                            <h4>
                                                <Select
                                                    isSearchable={true}
                                                    placeholder={"1"}
                                                    noOptionsMessage={() => "No option"}
                                                    value={this.state.selected_page}
                                                    aria-label="Table Page Number"
                                                    onChange={(e: ReactSelect) => this.setState({ selected_page: e },
                                                        () => this.changePage(e.value))}
                                                    className={"state_select"}
                                                    options={this.state.page_options}
                                                />
                                            </h4>
                                        </div>
                                    </section>

                                </div>
                                <div className="card-body p-0 m-0 table-responsive">
                                    <SimpleTable table_style='tableFixHead' columns={[
                                        {
                                            label: "Confirmation #", key: "ReqNum",
                                            rawFormat: (val) => {
                                                return <a href={"#!"} className={'tableNameLinkColor'} onClick={() => {
                                                    this.setState({ selectedExemption: val }, () =>
                                                        showModalNoOutsideClick(
                                                            PhysicianExemptionRecordModal.ID
                                                        )

                                                    );
                                                }}>{val.ReqNum}</a>
                                            },
                                            popoverText: "Click to sort by Confirmation #"
                                        },
                                        {
                                            label: "Status", key: "",
                                            rawFormat: (val) => {
                                                return <p>{`${getLabel(val.PhysicianStatus, this.state.statusOptions).label} `}</p>
                                            },
                                            popoverText: "Click to sort by Parent Name"
                                        },
                                        {
                                            label: "Parent/ Guardian Name", key: "",
                                            rawFormat: (val) => {
                                                return <p>{`${val.ParentFirstName} ${val.ParentLastName}`}</p>
                                            },
                                            popoverText: "Click to sort by Parent Name"
                                        },
                                        {
                                            label: "Child Name", key: "",
                                            rawFormat: (val) => {
                                                return <p>{`${val.ChildFirstName} ${val.ChildLastName}`}</p>
                                            },
                                            popoverText: "Click to sort by Child Name"
                                        },
                                        {
                                            label: "Child DOB", key: "ChildDOB",
                                            rawFormat: (val) => {
                                                return <p>{moment(val.ChildDOB).utc().format("MM/DD/YYYY")}</p>
                                            },
                                            popoverText: "Click to sort by Child Name"
                                        },
                                    ]} table_data={this.state.tableData} columnClickedCallback={(col => {
                                        this.useSorter(col);
                                    })} />
                                </div>
                            </div>
                        </div>
                    }

                </div>
            </div>
        </React.Fragment>)
    }
}