import React from "react";

import {buildDisplayString} from "./TableUtil";
import {Column, SimpleTableComponentProps} from "./TableBase";

interface SimpleBodyProps extends SimpleTableComponentProps {
    prependRows?: any
    appendRows?: any
    table_data:any[]
    rowBuilder?:(table_data:any[], columns:Column[]) => void
}
export default class SimpleBody extends React.Component<SimpleBodyProps, any>{

    public static defaultRow(col:Column, val:any){
        if(col.override) return col.override;
        // console.log(col, val)
        return (<td role="gridcell" tabIndex={0} className="TableDataWrap">{buildDisplayString(col, val)}</td>)
    }

    getRows(){
        return (this.props.table_data ? this.props.table_data.map((val:any)  => {
            return (<tr role="row">{
                    this.props.columns.map((col:Column) => {
                        return SimpleBody.defaultRow(col, val);
                    })
                }</tr>
            )
        }) : null);
    }

    render(): React.ReactElement | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return(<tbody>
            {this.props.prependRows ? this.props.prependRows : null}
            {this.props.rowBuilder ? this.props.rowBuilder(this.props.table_data, this.props.columns) : this.getRows()}
            {this.props.appendRows ? this.props.appendRows : null}
            </tbody>
          )
    }
}