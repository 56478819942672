import React from "react";
import {FilterPart} from "../FilterCard";
import {sweetalert} from "../../App";
import {CustomFieldBuilder} from "../../customfields/CustomFieldBuilder";
import Validator from "../../validation/Validator";
import {Validators} from "../../validation/Validators";
import {calculateAge, isBlank, isOnlyWhitespace} from "../../util/ValidationUtil";
import Overlay from "../Overlay";
import { hideModal, buildRow, parseDate, isEighteenOrOlder, showModalNoOutsideClick, getSelectLabels, getlabelsFromIDs, getLabel } from "../../util/FormatUtil";
import InBetweenOverlay from "../InBetweenOverlay";
import Select from "react-select";
import GetField from "../../customfields/GetField";
import { isEmptyObject } from "jquery";
import { BsFillRecord2Fill } from "react-icons/bs";
import { HintModal } from "./HintModal";
import { FaInfoCircle } from "react-icons/fa";
import PhoneInput from 'react-phone-number-input'



interface PatientInfoModalProps{
    submit:(e) => void,
    attachClearStateFunc?:(func) => void
    attachClearStateFuncGuardian?:(func) => void
    states:any
    gender:any
    ethnicity:any
    race:any
    countries:any
    facilities?
    portalPatients?
    fromWalkin?
    fromPatientMgmt?
    patientFields?
    selectedPatient?
    isAdmin?:boolean
}

interface PatientInfoModalState{
    showLoading:boolean
    showInBetween:boolean
    patientInfo
    selectedPatient?
    answeredQuestions?:any
    answeredQuestionsForGetField?
    hintHeader?;
    hintContent?;
}
export class PatientInfoModal extends React.Component<PatientInfoModalProps, PatientInfoModalState>{
    public static ID = "patientinfo";
    constructor(props) {
        super(props);
        this.state ={
            showLoading: false,
            showInBetween: false,
            selectedPatient: null,
            patientInfo: {},
            hintHeader: "Public User Information Hints",
            hintContent: [
                <div style={{fontSize: '1.5rem', marginBottom: '1rem'}}><BsFillRecord2Fill color={"#21212E"} size={25} /> Readonly Public User information can be updated via Public User Management.</div>
            ]
        } as any;
    }
    

    componentWillReceiveProps(nextProps: Readonly<PatientInfoModalProps>, nextContext: any) {
        if(!isEmptyObject(nextProps.selectedPatient) && nextProps.fromPatientMgmt){
            this.updateRows({label: nextProps.selectedPatient, value: nextProps.selectedPatient.id})
        } 
    }


    componentDidMount(): void {
        // $(`#${PatientInfoModal.ID}`).on('shown.bs.modal', function() {
        //     $(document).off('focusin.modal');
        // });
    }

    handleSave(){
        this.setState({showLoading: true}) 

        let newAnswerQuestionsObj = {}

        if(!isEmptyObject(this.state.patientInfo)){
            let answeredQuestionsArrays = Object.entries(this.state.patientInfo.CustomFieldData)
            answeredQuestionsArrays.map(a => {
                return newAnswerQuestionsObj[a[0]] = a[1]
            })
        }

        let allCustomQuestions = this.props.patientFields ? JSON.parse(JSON.stringify(this.props.patientFields)) : [];

        let tempAnsweredQuestions = {}
        if(allCustomQuestions?.length > 0){
            //remove questions that dont belong to this service
            allCustomQuestions.map((cq, i) => {
                if(!isEmptyObject(newAnswerQuestionsObj)){
                    for(const [key, value] of Object.entries(newAnswerQuestionsObj)){
                        if(cq.DisplayName === key){
                            tempAnsweredQuestions[key] = value
                            break
                        }
                    }
                }
            })

            //check if Date of Birth under 18. if so, Guardian fields are required
            if(!isEighteenOrOlder(tempAnsweredQuestions['Date of Birth'])){
                let guardianKeys = [];
                for (const key of Object.keys(tempAnsweredQuestions)) {
                    if(key.toLowerCase().includes('guardian')){
                        guardianKeys.push(key)
                    }
                }
                if(guardianKeys.length > 0){
                    this.setState({showLoading: false})
                    return sweetalert.fire({icon: 'error', title: '', text: 'Guardian information must be completed for patients under 18'})  
                }
            }

            //validate questions
            if(isEmptyObject(tempAnsweredQuestions)){
                this.setState({showLoading: false})
                return sweetalert.fire({icon: 'error', title: '', text: `Patient information must be complete`})
            }

            let patientSelected = false;
            if(this.state.selectedPatient && !this.props.fromPatientMgmt){
                patientSelected = true
            }

            let invalidCF = CustomFieldBuilder.validateCustomFields(tempAnsweredQuestions, allCustomQuestions, null, patientSelected)

            if(invalidCF){
                this.setState({showLoading: false})
                return
            }
        }

        this.props.submit({patientInfo: this.state.patientInfo})

        hideModal(PatientInfoModal.ID)
        this.setState({showLoading: false})
    }

    clearForWalkin = () => {

        this.setState({
            patientInfo: {
            }, 
            selectedPatient: null
        })
    }


    updateRows(e){
        if(!e){
            this.setState({patientInfo: {}, selectedPatient: null})
            return;
        }
        this.setState({selectedPatient: e, patientInfo: {}})
        let patientPortalInfo = e ? this.props.portalPatients.find(p => p.id === e.value) : {};
        let tempObj = {};
        let over18 = true;

        // Define a mapping configuration object
        const fieldMapping = {
            firstName: 'First Name',
            lastName: 'Last Name',
            dateOfBirth: 'Date of Birth',
            middleName: 'Middle Name',
            email: 'Email',
            streetAddress: 'Street Address',
            streetAddress2: 'Street Address cont.',
            city: 'City',
            state: 'State',
            zipcode: 'Zipcode',
            country: 'Country',
            county: 'County',
            ethnicityID: 'Ethnicity',
            genderID: 'Gender',
            raceID: 'Race',
            guardianFirstName: 'Guardian First Name',
            guardianLastName: 'Guardian Last Name',
            phoneNumber: 'Phone Number',
            FacilityIDs: 'FacilityIDs',
            TextOptOut: 'Opt-Out of Texts'
        };

        const otherKeys = ['uid']

        if(isEmptyObject(patientPortalInfo)){ 
          // console.log(" no patientPortalInfo",patientPortalInfo)
          return; 
        }
        try {
            for (const key of Object.keys(patientPortalInfo)) {
                if(this.props.fromPatientMgmt && key === 'FacilityIDs'){
                    tempObj[key] =  JSON.parse(patientPortalInfo[key])
                }
                if(otherKeys.includes(key)){
                    tempObj[key] = patientPortalInfo[key];
                    continue;
                }
                if (key === 'firstName' || key === 'lastName') {
                    tempObj[fieldMapping[key]] = patientPortalInfo[key];
                    continue;
                }
                if (key === 'dateOfBirth') {
                    tempObj[fieldMapping[key]] = patientPortalInfo[key];
                    over18 = isEighteenOrOlder(patientPortalInfo[key])
                    continue;
                }
                if (key === 'TextOptOut') {
                    tempObj[fieldMapping[key]] = patientPortalInfo[key];
                    continue;
                }
                if(key !== 'firstName' && key !== 'lastName' && key !== 'dateOfBirth' && !otherKeys.includes(key)){
                    if(key.toLowerCase().includes('guardian') && over18){
                        continue;
                    }
                    let matchingElement = this.props.patientFields.find(f => f.DisplayName === fieldMapping[key]);
                    if(matchingElement && !isEmptyObject(matchingElement) && matchingElement.Options.isActive){
                        if(key === 'ethnicityID'){
                            tempObj[fieldMapping[key]] = patientPortalInfo[key] ? this.props.ethnicity.find(f => f.value === patientPortalInfo[key]).label : null;
                            continue;
                        }
                        if(key === 'raceID'){
                            tempObj[fieldMapping[key]] = patientPortalInfo[key] ? this.props.race.find(f => f.value === patientPortalInfo[key]).label : null;
                            continue;
                        }
                        if(key === 'genderID'){
                            tempObj[fieldMapping[key]] = patientPortalInfo[key] ? this.props.gender.find(f => f.value === patientPortalInfo[key]).label : null;
                            continue;
                        }
                        if(key === 'state'){
                            tempObj[fieldMapping[key]] = patientPortalInfo[key] ? this.props.states.find(f => f.value === patientPortalInfo[key]).label : null;
                            continue;
                        }
                        if(key === 'country'){
                            tempObj[fieldMapping[key]] = patientPortalInfo[key] ? this.props.countries.find(f => f.value === patientPortalInfo[key]).label : null;
                            continue;
                        }
                        tempObj[fieldMapping[key]] = patientPortalInfo[key];
    
                    }
                }
            }
            if(patientPortalInfo.CustomFieldData && !isEmptyObject(JSON.parse(patientPortalInfo.CustomFieldData))){
                let parsedCustomFieldData = JSON.parse(patientPortalInfo.CustomFieldData)

                for (const key of Object.keys(parsedCustomFieldData)) {
                    tempObj[key] = parsedCustomFieldData[key]
                }
            }
        } catch (error) {
            return sweetalert.fire({icon: 'error', title: '', text: 'Could not update rows at this time'})
        }
        
        this.setState((prevState) => {
            const updatedPatientInfo = { ...prevState.patientInfo };
            if(isEmptyObject(updatedPatientInfo)){
                updatedPatientInfo.CustomFieldData = {};
            }
            for (const key in tempObj) {
                if (tempObj.hasOwnProperty(key)) {
                    updatedPatientInfo.CustomFieldData[key] = tempObj[key];
                }
            }
                    return {
                patientInfo: updatedPatientInfo,
            };
        })
    }

    render() {

        // console.log('PatientInfoModal props', this.props)
        // console.log('PatientInfoModal state', this.state)

        let over18 = this.state.patientInfo && this.state.patientInfo.CustomFieldData && this.state.patientInfo.CustomFieldData['Date of Birth'] ? isEighteenOrOlder(this.state.patientInfo.CustomFieldData['Date of Birth']) : true;

            return  (
                <React.Fragment>
                    <Overlay show_loading={this.state.showLoading} zIndex={100005}/>
                    <InBetweenOverlay showInBetween={this.state.showInBetween} zIndex={100003} />
                    <HintModal
                        zIndex={100006}
                        handleInBetweenOverlay={() => this.setState({showInBetween: false})}
                        header={this.state.hintHeader}
                        content={this.state.hintContent}
                    />
                    <div className="modal fade form_modal" id={PatientInfoModal.ID} aria-modal={true} tabIndex={-1} role="dialog"
                        aria-label=""
                        aria-hidden="true">
                        <div className="modal-dialog modal-lg modal-xl" role="document">
                            <div className="modal-content">
                                <div className="modal-body p-0 m-0">
                                    <div className="container-fluid">
                                        <div className={"row"}>
                                            <div className="col-12 pt-2">
                                                <div className="card mt-2">
                                                    <div className="card-header verlag-bold">
                                                        <div className="row">
                                                            <div className="col-10">
                                                                <h4>
                                                                    Public User
                                                                    {this.props.fromWalkin && <FaInfoCircle
                                                                        tabIndex={5}
                                                                        className={"ml-1"}
                                                                        size={25}
                                                                        color={"#21212E"}
                                                                        onClick={() => {showModalNoOutsideClick(HintModal.ID); this.setState({showInBetween: true})}}
                                                                    />}
                                                                </h4>
                                                            </div>
                                                            <div className="col-2">
                                                                <button style={{outline: 'none'}} type="button" className="close pr-4"
                                                                        aria-label="Close" onClick={() => hideModal(PatientInfoModal.ID)}>
                                                                    <span aria-hidden="true" style={{fontSize: '1.5em', fontWeight: 'bold'}}>&times;</span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-body">
                                                    {this.props.fromPatientMgmt && this.state.selectedPatient &&
                                                    <>
                                                        <div className="form-group row" data-toggle={'tooltip'} data-placement={'top'} title={'UID'}>
                                                            <label htmlFor={'UID'} className="col-sm-4 col-form-label">UID</label>
                                                            <div className="col-12 col-md-8 pl-md-0 pr-md-1" id={'UID'}>
                                                                <input className={"form-control"}
                                                                    id={'UID'}
                                                                    maxLength={50}
                                                                    autoComplete={"off"}
                                                                    type={"search"}
                                                                    aria-label="User ID"
                                                                    name={"UID"}
                                                                    readOnly={true}
                                                                    value={this.state.patientInfo && this.state.patientInfo.CustomFieldData && this.state.patientInfo.CustomFieldData.uid}
                                                                />
                                                            </div>
                                                        </div>
                                                        {/* {/* <div className="form-group row" data-toggle={'tooltip'} data-placement={'top'} title={'Facilities'}>
                                                            <label htmlFor={'Facilities'} className="col-sm-4 col-form-label">Facilities</label>
                                                            <div className="col-12 col-md-8 pl-0 pr-4" id={'Facilities'}>
                                                            <Select
                                                                isSearchable={true}
                                                                isMulti={true}
                                                                placeholder={"Please Select..."}
                                                                noOptionsMessage={() => "No option"}
                                                                value={ this.state.patientInfo && this.state.patientInfo.CustomFieldData && this.state.patientInfo.CustomFieldData.FacilityIDs ? getlabelsFromIDs(this.state.patientInfo.CustomFieldData.FacilityIDs, this.props.facilities) : [] }
                                                                onChange={(e) => {
                                                                    let updatedFacilityIDs = [];
                                                                    let patientCurrentFacilityIDs = this.state.patientInfo && this.state.patientInfo.CustomFieldData && this.state.patientInfo.CustomFieldData.FacilityIDs ? this.state.patientInfo.CustomFieldData.FacilityIDs : null;
                                                                    let dropDownFacilityIDs = this.props.facilities.filter(f => patientCurrentFacilityIDs.includes(f.ID));
                                                                    dropDownFacilityIDs = dropDownFacilityIDs.map(m => {return m.ID})

                                                                    if(e){
                                                                        if(e.length > dropDownFacilityIDs.length){
                                                                            patientCurrentFacilityIDs.push(e[e.length-1].value)
                                                                            updatedFacilityIDs = updatedFacilityIDs.concat(patientCurrentFacilityIDs)
                                                                        }
                                                                        else{
                                                                            for (let i = 0; i < e.length; i++) {
                                                                                for (let j = 0; j < dropDownFacilityIDs.length; j++) {
                                                                                    if(e[i].value === dropDownFacilityIDs[j]){
                                                                                        dropDownFacilityIDs.splice(j, 1)
                                                                                    }                                                                                   
                                                                                }
                                                                            }
                                                                            for (let i = 0; i < patientCurrentFacilityIDs.length; i++) {
                                                                                if(patientCurrentFacilityIDs[i] === dropDownFacilityIDs[0]){
                                                                                    patientCurrentFacilityIDs.splice(i, 1)
                                                                                }                                                                                
                                                                            }
                                                                            updatedFacilityIDs = patientCurrentFacilityIDs;
                                                                        }
                                                                    }
                                                                    else{
                                                                        for (let i = 0; i < dropDownFacilityIDs.length; i++) {
                                                                            for (let j = 0; j < patientCurrentFacilityIDs.length; j++) {
                                                                                if(dropDownFacilityIDs[i] === patientCurrentFacilityIDs[j]){
                                                                                    patientCurrentFacilityIDs.splice(j, 1)
                                                                                }                                                                                
                                                                            }                                                                            
                                                                        }
                                                                        updatedFacilityIDs = patientCurrentFacilityIDs;
                                                                    }
                                                                    this.setState((prevState) => {
                                                                        const updatedPatientInfo = { ...prevState.patientInfo };
                                                                        if(isEmptyObject(updatedPatientInfo)){
                                                                            updatedPatientInfo.CustomFieldData = {};
                                                                        }
                                                                        updatedPatientInfo.CustomFieldData.FacilityIDs = updatedFacilityIDs;
                                                                                return {
                                                                            patientInfo: updatedPatientInfo,
                                                                        };
                                                                    })
                                                                }}
                                                                className={"state_select"}
                                                                options={getSelectLabels(this.props.facilities)}
                                                            />
                                                        </div> */}
                                                    {/* </div> */}
                                                    </>
                                                    }
                                                        {this.props.fromWalkin && 
                                                        <div className="form-group row" data-toggle={'tooltip'} data-placement={'top'} title={'Search for existing patient'}>
                                                            <label htmlFor={'PatientSearch'} className="col-sm-4 col-form-label">{'Patient Search'}</label>
                                                            <div className="col-12 col-md-8 pl-0 pr-4" id={'PatientSearch'}>
                                                                <Select
                                                                    readOnly={!this.props.isAdmin}
                                                                    disabled={!this.props.isAdmin}
                                                                    id="PatientSearch"
                                                                    isClearable={true}
                                                                    isSearchable={true}
                                                                    placeholder={<div className="accessibilityText">Please Select...</div>}
                                                                    className={"state_select"}
                                                                    name={"PatientSearch"}
                                                                    aria-label="Patient Search"
                                                                    onChange={(e)=>{
                                                                        this.updateRows(e)
                                                                    }}
                                                                    value={this.state.selectedPatient}
                                                                    options={this.props.portalPatients?.map(p => {return {label: `${p.firstName} ${p.lastName} - ${parseDate(p.dateOfBirth)} - ${p.uid}`, value: p.id}})}
                                                                />
                                                                    {/* <div className={this.state.selectedPatient ? "row" : "d-none"}>
                                                                        <div className="col-12 pr-1 mb-2 m-0 text-danger verlag-bold" style={{fontSize: '1rem'}} >*if patient information has changed, please edit the profile on the Patient Management page.</div>
                                                                    </div> */}
                                                                </div>
                                                            </div>
                                                        }                                                        
                                                        <div className="row">
                                                            {this.props.patientFields && this.props.patientFields.length > 0 && this.props.patientFields.map((val, index) => {
                                                                if (val.Options.isActive) {
                                                                    if(val.DisplayName === 'Country'){
                                                                      // console.log(' dont show me')
                                                                      return; 
                                                                    }
                                                                    if(over18 && val.DisplayName.toLowerCase().includes('guardian')){
                                                                        return <div className="d-none"></div>
                                                                    }
                                                                    return (
                                                                    <div className="col-12">
                                                                        <div className="form-group row">
                                                                        <GetField fromPatientMgmt={this.props.fromPatientMgmt} patientSearchReadOnly={true} val={val} selectedItem={ this.state.patientInfo ? this.state.patientInfo : {} } handleChange={(e) => { this.setState({ patientInfo: e, }); }} />
                                                                        </div>
                                                                    </div>
                                                                    );
                                                                }
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-footer" style={{backgroundColor: 'transparent', borderTop: 'none'}}>
                                            {/* {this.props.isAdmin && <button className={"btn btn-primary float-right mb-3"} onClick={() => this.handleSave()}> Save </button>} */}
                                            {!this.props.isAdmin && <button className={"btn btn-primary float-right mb-3"} onClick={() => hideModal(PatientInfoModal.ID)}> Close </button>}
                                         </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                
                </React.Fragment>
            )
        }

}