import React from "react";
// import CancelAppointment from "./modals/CancelAppointment";
import Overlay from "./Overlay";
// import { AppointmentSelectionModal } from "./modals/AppointmentSelectionModal";
import Banner from "../types/Banner";
import SystemAPI from "../network/SystemAPI";

interface NavState {
    showLoading: boolean
    banner?: Banner
    logo: string
}
class Nav extends React.Component<{}, NavState> {

    constructor(props) {
        super(props);
        this.state = {
            showLoading: false,
            logo: ""
        }
        this.getBannerElem = this.getBannerElem.bind(this);
    }

    componentDidMount() {
        this.setState({ showLoading: true }, () => {
            let currentURL = window.location.href;
            SystemAPI.getProductBrandingFromURL(currentURL).then(data => {
                let ProductID = data.ProductID;
                this.setState({ showLoading: false })
            })
        })
    }

    getBannerElem() {
        if (!this.state.banner)
            return null;
        return (
            <div className="alert alert-danger m-0 d-print-none" role="alert">
                <p className="primary-black m-0">
                    {this.state.banner.Text}
                </p>
            </div>
        )
    }


    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {

        return (
            <React.Fragment>
                {/* <AppointmentSelectionModal /> */}
                <Overlay show_loading={this.state.showLoading} />
                {/* <CancelAppointment /> */}

                <div className={"p-0 m-0 nav-wrapper"}>
                    <div className="p-0 m-0 container-fluid">
                        {this.getBannerElem()}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Nav;