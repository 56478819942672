import React from "react"
import { buildRow, hideModal } from "../../util/FormatUtil";

interface HL7PreviewModalState{

}

interface HL7PreviewModalProps{
    selectedMessage
}



class HL7PreviewModal extends React.Component<HL7PreviewModalProps, HL7PreviewModalState>{
    public static ID = "HL7PreviewModal"
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    formatFinalHL7(){
        let jsx;
        if(!this.props.selectedMessage.FinalHL7){
            return
        }
        
        let mshStartSubstringIndex = this.props.selectedMessage.FinalHL7.indexOf('MSH');
        // let sftStartSubstringIndex = this.props.selectedMessage.FinalHL7.indexOf('SFT');
        let pidStartSubstringIndex = this.props.selectedMessage.FinalHL7.indexOf('PID');
        let pd1StartSubstringIndex = this.props.selectedMessage.FinalHL7.indexOf('PD1');
        let nk1StartSubstringIndex = this.props.selectedMessage.FinalHL7.indexOf('NK1');
        let orcStartSubstringIndex = this.props.selectedMessage.FinalHL7.indexOf('ORC');
        let rxaStartSubstringIndex = this.props.selectedMessage.FinalHL7.indexOf('RXA'); //multiple
        let btsStartSubstringIndex = this.props.selectedMessage.FinalHL7.indexOf('BTS');

        let rxaString = this.props.selectedMessage.FinalHL7.substring(rxaStartSubstringIndex, this.props.selectedMessage.FinalHL7.length);     
        const segments = rxaString.split('RXA|');
        segments.shift();
        const rxaJSX = segments.map((segment, index) => (
            <p>RXA|{segment}</p>
        ))       

        jsx = <div>
            <p>{this.props.selectedMessage.FinalHL7.substring(mshStartSubstringIndex, pidStartSubstringIndex)}</p>
            <p>{this.props.selectedMessage.FinalHL7.substring(pidStartSubstringIndex, pd1StartSubstringIndex)}</p>
            <p>{this.props.selectedMessage.FinalHL7.substring(pd1StartSubstringIndex, nk1StartSubstringIndex)}</p>
            <p>{this.props.selectedMessage.FinalHL7.substring(nk1StartSubstringIndex, orcStartSubstringIndex)}</p>
            <p>{this.props.selectedMessage.FinalHL7.substring(orcStartSubstringIndex, rxaStartSubstringIndex)}</p>
            <p>{rxaJSX}</p>
            </div>

        return jsx;
    }

    render() {
        return (
        <>
            <div className="modal fade form_modal" id={HL7PreviewModal.ID} tabIndex={-1} role="dialog"
                 aria-label="HL7 Message Preview"
                 aria-hidden="true">
                <div className="modal-dialog modal-lg modal-xl" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="container-fluid  min-vh-10">
                                <div className={"row"}>
                                    <div className="col-12 pt-2">
                                        <div className="card mb-2">
                                            <div className="card-header verlag-bold">
                                                <h4 style={{fontWeight: 'bold'}}>HL7 Message Preview
                                                    <button style={{outline: 'none'}}  type="button" className="close pr-4" data-dismiss="modal" aria-label="Close">
                                                        <span aria-hidden="true" style={{fontSize: '1.5em', fontWeight: 'bold'}}>&times;</span>
                                                    </button>
                                                </h4>
                                            </div>
                                            <div className="card-body">
                                                <p>{this.props.selectedMessage.FinalFHS}</p>
                                                <p>{this.props.selectedMessage.FinalBHS}</p>
                                                {this.formatFinalHL7()}
                                                <p>{this.props.selectedMessage.FinalBTS}</p>
                                                <p>{this.props.selectedMessage.FinalFTS}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer mb-2 mr-2 ml-0" style={{backgroundColor: 'transparent', borderTop: 'none'}}>
                                    <button type="button"  className={'btn btn-primary float-right'} onClick={() => hideModal(HL7PreviewModal.ID)}>Ok</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
        )
    }
}

export default HL7PreviewModal;