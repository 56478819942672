import React from "react";
import Overlay from "../Overlay";
import { sweetalert } from "../../App";
import Select from "react-select";
import NetworkUtil from "../../network/NetworkUtil";
import SimpleTable from "../tables/SimpleTable";
import SystemAPI from "../../network/SystemAPI";
import Banner from "../../types/Banner";
import AdminAPI from "../../network/AdminAPI";
import FilterCard, { FIELD_TYPE, ReactSelect } from "../FilterCard";
import {
  getBoolSelectOptions,
  getBoolSelectVal,
  getBoolSelectYN,
  getLabel,
  getLabelForDocument,
  getNumPages,
  getPageOptions,
  getReactSelectAriaLabel,
  hideModal,
  showModalNoOutsideClick,
  slicePages,
} from "../../util/FormatUtil";

import { Column } from "../tables/TableBase";
import { Sorter, TableOrder } from "../../sorting/Sorter";
import PaginationTool from "../PaginationTool";
import { AdminPages } from "./AdminNav";
import { DocumentUploadModal } from "../modals/DocumentUploadModal";
import { AiOutlineClose } from "react-icons/ai";

const ITEMS_PER_PAGE = 25;



interface DocumentUploadState {
  showLoading:boolean;
  documents?:any;
  selectedDocument;
  documentQueryResults;
  tableData: {}[];
  filter?: {
    ID
  };
  direction?: TableOrder;
  page_options: ReactSelect[];
  selected_page?: { label; value };
}

export class DocumentUpload extends React.Component<
  any,
  DocumentUploadState
> {
  
  constructor(props) {
    super(props);

    this.state = {
      showLoading: false,
      selectedDocument: {},
      tableData: [] as any,
      documentQueryResults: [] as any,
      filter: { ID: null },
      direction: "asc",
      page_options: [{ label: "1", value: "1" }],
      selected_page: { label: "1", value: 1 },
    };

  }

  componentDidMount() {
    document.title = 'File Management Page';
    let currentURL = window.location.href;
    this.setState({showLoading: true}, () => {
        SystemAPI.getProductBrandingFromURL(currentURL).then(dataFromProduct => {
            AdminAPI.getDocuments().then(data => {
                let documentList = [];
                this.setState({documents: data.documents, showLoading: false})
            })
        })
    })

  }

  queryDocumentUploadData(page: number) {
    this.setState({ showLoading: true }, async () => {
      let results = await AdminAPI.filterDocuments({
        filter: {
          ID: this.state.filter.ID,
        },
      });
      if(results.data.length < 1){
        this.setState({showLoading: false, tableData: [], documentQueryResults: null})
        return sweetalert.fire({title: '', text: 'No Files returned', icon: 'info'})
      }
      if (!results.success) {
        return sweetalert.fire({
          title: "",
          text: "Unable to filter File data at this time",
          icon: "error",
        });
      }

      this.setState({
        tableData: slicePages(results.data, page, ITEMS_PER_PAGE),
        documentQueryResults: results.data,
        page_options: getPageOptions(getNumPages(results.data, ITEMS_PER_PAGE)),
      });

      // let productID = JSON.parse(JSON.stringify(this.state.productID))

      AdminAPI.getDocuments().then(data => {
        let documentList = [];
        this.setState({documents: data.documents, showLoading: false})
        })
    });
  }

  handleExportToCSV() {
    this.setState({ showLoading: true }, async () => {
      await NetworkUtil.downloadCSV(
        "/api/admin/document/csv",
        "File Upload Management.xlsx",
        { filter: this.state.filter });
      this.setState({ showLoading: false });
    });
  }

  changePage(page: number) {
    let allServices = this.state.documentQueryResults;
    let returnData = slicePages(allServices, page, ITEMS_PER_PAGE);
    this.setState({ tableData: returnData });
  }

  useSorter(col: Column) {
    let sorter = new Sorter<any>();

    this.setState({
      tableData: sorter.sortByKey(
        this.state.tableData,
        col.key as keyof any,
        this.state.direction
      ),
      direction: this.state.direction === "asc" ? "desc" : "asc",
    });
  }

  deleteDocument(obj){
    // let productID = JSON.parse(JSON.stringify(this.state.productID))
    this.setState({ showLoading: true }, async () => {
        let response = await AdminAPI.deleteDocument(obj.ID, obj.URL);
        if(!response.success){
            this.setState({showLoading: false})
            return sweetalert.fire({icon: 'error', title: '', text: response.reason})
        }

        sweetalert.fire({icon: "success", title: '', text: `${obj.DocumentName} deleted successfully`}).then(res => {
          if(res.isConfirmed){
            this.setState((prevState)=>({
                filter: {
                    ...prevState.filter,
                    ID: null
                }
            }))
            this.queryDocumentUploadData(1)
          }
        })
    })

  }



  render() {

    // console.log('DocumentUpload state', this.state)
    return (
      <React.Fragment>
        <PaginationTool />
        <Overlay show_loading={this.state.showLoading} />
        <DocumentUploadModal
          documents={this.state.documents}
          selectedDocument={this.state.selectedDocument}
          requery={() => this.queryDocumentUploadData(1)}
        />
        <div className="container-fluid ">
          <div className={"row"}>
            <div className="col-12 col-md-12 col-lg-8 col-xl-5 pt-2">
              <main id="main-content" tabIndex={-1} aria-label="File Management">
                <div className="card mb-2">
                  <div className="card-header verlag-bold">
                    <h3>File Management</h3>
                  </div>
                  <div className="card-body">
                  <div className="form-group row">
                      <label htmlFor={'Facility'} className="col-sm-4 col-form-label" style={{fontWeight: 'bold'}}>File Name</label>
                      <div className="col-sm-8  p-0 m-0" id={'FileName'}>
                          <Select
                              isSearchable={ true}
                              placeholder={<div className="accessibilityText">Please Select...</div>}
                              noOptionsMessage={()=>  "No option"}
                              className={"state_select"}
                              aria-label={getReactSelectAriaLabel("File Name", this.state.filter && this.state.filter.ID ? getLabelForDocument(this.state.filter.ID, this.state.documents) : null, true)}
                              options={this.state.documents && this.state.documents.length > 0 ? this.state.documents.map(m => {return {label: m.DocumentName, value: m.ID}}) : []}
                              onChange={(e) => this.setState((prevState)=>({
                                  filter: {
                                      ...prevState.filter,
                                      ID: e.value
                                  }
                                  }))
                              }
                              value={this.state.filter && this.state.filter.ID ? getLabelForDocument(this.state.filter.ID, this.state.documents) : null}
                          />
                      </div>
                  </div>
                  </div>
                  <div className="card-footer">
                    <button
                      className={"btn immySubmitButtonOutline"}
                      onClick={() => {
                        this.setState(
                          {
                            selected_page: { label: 1, value: 1 },
                          },
                          () => {
                            this.queryDocumentUploadData(
                              this.state.selected_page.value
                            );
                          }
                        );
                      }}
                    >
                      Search
                    </button>
                    <button
                      className={this.state.filter && (this.state.filter.ID || this.state.filter.ID === 0) ? "btn immyClearButtonOutline ml-2" : "d-none"}
                      onClick={() => {
                        this.setState({filter: {ID: null}});
                      }}
                    >
                      Clear
                    </button>
                    <button
                      className="btn btn-outline-success float-right"
                      onClick={() =>
                        this.setState(
                          {
                            selectedDocument: {
                              ID: null,
                              DocumentName: "",
                              URL: "",
                            },
                          },
                          () => {
                            showModalNoOutsideClick("documentupload")
                          }
                        )
                      }
                    >
                      Create New
                    </button>
                  </div>
                </div>
              </main>
            </div>
            {this.state.tableData && this.state.tableData.length > 0 && (
              <div className="col-12 col-md-12 col-lg-12 pt-2">
                <div className="card mt-2" style={{marginBottom: '4rem'}}>
                  <div className="card-header verlag-bold stickToTop">
                    <h4 className="text-center text-md-left">
                      Files
                      <section className="tableHeaderSection float-md-right d-flex justify-content-around">
                        <h4 className={"float-md-right"} aria-label="Total Records" role="alert">
                          Total: {this.state.documentQueryResults.length }
                        </h4>
                        <h4 className="d-inline-block float-right align-middle pr-2 ml-5">
                          Page{" "}
                        </h4>
                        <div className=" align-middle float-right pages ">
                          <Select
                            isSearchable={true}
                            placeholder={"1"}
                            noOptionsMessage={() => "No option"}
                            value={this.state.selected_page}
                            aria-label="Table Page Number"
                            onChange={(e: ReactSelect) =>
                              this.setState({ selected_page: e }, () =>
                                this.changePage(e.value)
                              )
                            }
                            className={"state_select"}
                            options={this.state.page_options}
                          />
                        </div>
                      </section>
                      <button
                        className={"d-none d-md-inline btn btn-outline-primary ml-3"}
                        onClick={() => this.handleExportToCSV()}
                      >
                        Export to CSV
                      </button>
                    </h4>
                  </div>
                  <div className="card-body p-0 m-0 table-responsive">
                    <SimpleTable
                      columns={[
                        {
                          label: "File Name",
                          key: "DocumentName",
                          popoverText: "Click to Sort By File Name",
                          rawFormat: (val) => {
                            return (
                              <a
                                href={"#"}
                                className={'tableNameLinkColor'}
                                onClick={() => {
                                  this.setState({selectedDocument: val }, () => {
                                    showModalNoOutsideClick("documentupload")
                                  });
                                }}
                              >
                                {val.DocumentName}
                              </a>
                            );
                          },
                        },
                        {
                          label: "URL",
                          key: "URL",
                          popoverText: "Click to Sort By URL",
                        },
                        {
                            label: "View",
                            key: "",
                            rawFormat: (val) => {
                              return (
                                <a
                                  href={val.URL}
                                  target="_blank"
                                >
                                  Click Here
                                </a>
                              );
                            },
                          },
                          {
                            label: "Delete",
                            key: "",
                            rawFormat: (val) => {
                              return (
                                <div className="pl-2"  tabIndex={0} aria-label="Remove Test" role="button" onKeyDown={(e) => {if(e.key === "Enter"){this.deleteDocument(val) }}}  onClick={(e) => this.deleteDocument(val) } >
                                    <AiOutlineClose color={"red"} size={30} />
                                </div>
                              );
                            },
                          },
                      ]}
                      table_data={this.state.tableData}
                      columnClickedCallback={(col) => {
                        this.useSorter(col);
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}