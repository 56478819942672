import NetworkUtil from "./NetworkUtil";

export default class AdminAPI {

    public static async getAllHL7Messages():Promise<{success:boolean, reason:string, data?}>{
        return NetworkUtil.makeGet("/api/admin/connect");
    }

    public static async filterHL7Message(filter:{filter:{ReqNum}}):Promise<{success:boolean, reason:string, data}>{
        return NetworkUtil.makePost("/api/admin/connect/filter", filter)
    }

    public static async getHL7FileFromCloud(reqNum):Promise<any>{
        return NetworkUtil.makePostForBlob("/api/admin/connect/getHL7FileFromCloud", {reqNum: reqNum});
    }

    public static async getHL7Exemption(reqNum):Promise<{success:boolean, reason:string, data}>{
        return NetworkUtil.makePost("/api/admin/connect/getHL7Exemption", {ReqNum: reqNum});
    }


}