import React from "react";
import Overlay from "../Overlay";
import { sweetalert } from "../../App";
import Select from "react-select";
import NetworkUtil from "../../network/NetworkUtil";
import SimpleTable from "../tables/SimpleTable";
import SystemAPI from "../../network/SystemAPI";
import Banner from "../../types/Banner";
import AdminAPI from "../../network/AdminAPI";
import FilterCard, { FIELD_TYPE, ReactSelect } from "../FilterCard";
import {
  getBoolSelectOptions,
  getBoolSelectYN,
  getNumPages,
  getPageOptions,
  hideModal,
  showModalNoOutsideClick,
  slicePages,
} from "../../util/FormatUtil";
// import { ResultsManagementModal } from "../modals/ResultsManagementModal";
import { Column } from "../tables/TableBase";
import { Sorter, TableOrder } from "../../sorting/Sorter";
import PaginationTool from "../PaginationTool";
import { BannerManagementModal } from "../modals/BannerManagementModal";

const ITEMS_PER_PAGE = 25;



interface BannerManagementState {
  showLoading:boolean;
  banners?:any;
  selectedBanner: Banner;
  bannerQueryResults;
  tableData: {}[];
  filter?: {
    Product:string;
    Active:boolean;
  };
  direction?: TableOrder;
  page_options: ReactSelect[];
  selected_page?: { label; value };
  productList?:any[]
  productID?
}

export class BannerManagement extends React.Component<
  any,
  BannerManagementState
> {
  
  constructor(props) {
    super(props);

    this.state = {
      showLoading: false,
      selectedBanner: {} as Banner,
      tableData: [] as any,
      bannerQueryResults: [] as any,
      filter: { Product: null, Active: false },
      direction: "asc",
      page_options: [{ label: "1", value: "1" }],
      selected_page: { label: "1", value: 1 },
    };

  }

  componentDidMount() {
    document.title = 'Banner Management Page';
    this.setState({showLoading: true}, () => {
      let currentURL = window.location.href;
      SystemAPI.getProductBrandingFromURL(currentURL).then(data => {
          let productID = data.ProductID;
          SystemAPI.getBanners().then(data => {
            let productList = [];
            data.banners.map(d => {
              productList.push({label: d.ProductName, value: d.BrandingID})
            })
            this.setState({banners: data.banners, productList: productList, productID: productID, showLoading: false})
            this.setState({
                    tableData: slicePages(data.banners, 1, ITEMS_PER_PAGE),
                    bannerQueryResults: data.banners,
                    page_options: getPageOptions(getNumPages(data.banners, ITEMS_PER_PAGE)),
                    showLoading: false,
                  });
          })
      })
    })
  }

  // queryBannerData(page: number) {
  //   this.setState({ showLoading: true }, async () => {
  //     let results = await AdminAPI.filterBanner({
  //     });
  //     if(results.data.length < 1){
  //       this.setState({showLoading: false, tableData: [], bannerQueryResults: null})
  //       return sweetalert.fire({title: '', text: 'No Banners returned', icon: 'info'})
  //     }
  //     if (!results.success) {
  //       return sweetalert.fire({
  //         title: "",
  //         text: "Unable to filter Banners data at this time",
  //         icon: "error",
  //       });
  //     }

  //     this.setState({
  //       tableData: slicePages(results.data, page, ITEMS_PER_PAGE),
  //       bannerQueryResults: results.data,
  //       page_options: getPageOptions(getNumPages(results.data, ITEMS_PER_PAGE)),
  //       showLoading: false,
  //     });
  //   });
  // }

  createOrModifyBanner(banner: Banner) {
    hideModal(BannerManagementModal.ID)
    this.setState({ showLoading: true }, async () => {
      if (banner.BannerID === null) {
        let response = await AdminAPI.createBanner(banner);
        if (!response.success) {
          let msg = "Unable to create Banner at this time.";
          if (response.reason) {
            msg = response.reason;
          }
          return sweetalert.fire({
            icon: "error",
            title: "Attention",
            text: msg,
            allowOutsideClick: false
          }).then((response) => {
            if(response.isConfirmed){
              window.location.reload()
            }
          });
        }
      } else {
        let response = await AdminAPI.updateBanner(banner);
        if (!response.success) {
          let msg = "Unable to update Banner at this time.";
          if (response.reason) {
            msg = response.reason;
          }
          return sweetalert.fire({
            icon: "error",
            title: "Attention",
            text: msg,
            allowOutsideClick: false
          }).then((response) => {
            if(response.isConfirmed){
              window.location.reload()
            }
          });
        }
      }

      sweetalert
        .fire({ icon: "success", title: "", html: "<h5>Banner updated</h5><p>Page reload is required for changes to take effect</p>" })
        .then(() => {
          this.setState(
            {
              selectedBanner: {
                BannerID: null,
                Text: '',
                Display: 0,
                BrandingID: null,
                ProductName: '',
              } as Banner,
            },
            () => {
              window.location.reload()
            }
          );
        });
    });
  }

  handleExportToCSV() {
    let filterCopy = JSON.parse(JSON.stringify(this.state.filter))
    filterCopy.ProductID = this.state.productID;
    this.setState({ showLoading: true }, async () => {
      await NetworkUtil.downloadCSV(
        "/api/admin/banners/csv",
        "Banner Management Data.xlsx",
        { filter: filterCopy });
      this.setState({ showLoading: false });
    });
  }

  changePage(page: number) {
    let allServices = this.state.bannerQueryResults;
    let returnData = slicePages(allServices, page, ITEMS_PER_PAGE);
    this.setState({ tableData: returnData });
  }

  renderBannerFilterFields() {
    return (
      <FilterCard
        fields={[
          {
            label: "Product",
            key: "Product",
            type: FIELD_TYPE.SELECT,
            options: this.state.productList,
            isMapped: true,
            textType: "text",
            isFilter: true
          },
          {
            label: "Active?",
            key: "Active",
            type: FIELD_TYPE.SELECT,
            options: getBoolSelectOptions(),
            isMapped: true,
            textType: "text",
            isFilter: true
          },
        ]}
        filterChanged={(e) => this.setState({ filter: e })}
      />
    );
  }

  useSorter(col: Column) {
    let sorter = new Sorter<any>();

    this.setState({
      tableData: sorter.sortByKey(
        this.state.tableData,
        col.key as keyof any,
        this.state.direction
      ),
      direction: this.state.direction === "asc" ? "desc" : "asc",
    });
  }



  render() {

    // console.log('BannerMgmt state', this.state)
    return (
      <React.Fragment>
        <PaginationTool />
        <Overlay show_loading={this.state.showLoading} />
        <BannerManagementModal
          selectedBanner={this.state.selectedBanner}
          onSubmit={(banner) => {
            hideModal(BannerManagementModal.ID);
            this.createOrModifyBanner(banner);
          }}
        />
        <div className="container-fluid ">
          <div className={"row"}>
            {/* <div className="col-12 col-md-12 col-lg-8 col-xl-5 pt-2">
              <main id="main-content" tabIndex={-1} aria-label="Banner Management">
                <div className="card mb-2">
                  <div className="card-header verlag-bold">
                    <h3>Banner Management</h3>
                  </div>
                  <div className="card-body">
                  {this.renderBannerFilterFields()}
                  </div>
                  <div className="card-footer">
                    <button
                      className={"btn immySubmitButtonOutline"}
                      onClick={() => {
                        this.setState(
                          {
                            selected_page: { label: 1, value: 1 },
                          },
                          () => {
                            this.queryBannerData(
                              this.state.selected_page.value
                            );
                          }
                        );
                      }}
                    >
                      Search
                    </button>
                    {/* <button
                      className="btn btn-outline-success float-right"
                      onClick={() =>
                        this.setState(
                          {
                            selectedBanner: {
                              BannerID: null,
                              Text: "",
                              Display: 0,
                              BrandingID: null,
                              ProductName: ''
                            } as Banner,
                          },
                          () => {
                            showModalNoOutsideClick(BannerManagementModal.ID);
                          }
                        )
                      }
                    >
                      Create New
                    </button>
                  </div>
                </div>
              </main>
            </div> */}
            {this.state.tableData && this.state.tableData.length > 0 && (
              <div className="col-12 col-md-12 col-lg-12 pt-2">
                <main id="main-content" tabIndex={-1} aria-label="Banner Management">
                  <div className="card mt-2" style={{marginBottom: '4rem'}}>
                    <div className="card-header verlag-bold stickToTop">
                      <h4 className="text-center text-md-left">
                        Banners
                        <section className="tableHeaderSection float-md-right d-flex justify-content-around">
                          <h4 className={"float-md-right"} aria-label="Total Records" role="alert">
                            Total: {this.state.bannerQueryResults.length }
                          </h4>
                          <h4 className="d-inline-block float-right align-middle pr-2 ml-5">
                            Page{" "}
                          </h4>
                          <div className=" align-middle float-right pages ">
                            <Select
                              isSearchable={true}
                              placeholder={"1"}
                              noOptionsMessage={() => "No option"}
                              value={this.state.selected_page}
                              aria-label="Table Page Number"
                              onChange={(e: ReactSelect) =>
                                this.setState({ selected_page: e }, () =>
                                  this.changePage(e.value)
                                )
                              }
                              className={"state_select"}
                              options={this.state.page_options}
                            />
                          </div>
                        </section>
                        <button
                          className={"d-none d-md-inline btn btn-outline-primary ml-3"}
                          onClick={() => this.handleExportToCSV()}
                        >
                          Export to CSV
                        </button>
                      </h4>
                    </div>
                    <div className="card-body p-0 m-0 table-responsive">
                      <SimpleTable
                        columns={[
                          {
                            label: "Product",
                            key: "ProductName",
                            popoverText: "Click to Sort By Product",
                            rawFormat: (val) => {
                              return (
                                <a
                                  href={"#"}
                                  className={'tableNameLinkColor'}
                                  onClick={() => {
                                    this.setState({ selectedBanner: val }, () =>
                                      showModalNoOutsideClick(
                                        BannerManagementModal.ID
                                      )
                                    );
                                  }}
                                >
                                  {val.ProductName}
                                </a>
                              );
                            },
                          },
                          {
                            label: "Banner Text",
                            key: "Text",
                            popoverText: "Click to Sort By Banner Text",
                          },
                          {
                            label: "Active",
                            key: "Display",
                            popoverText: "Click to Sort By Active",
                            rawFormat: (val) => {
                              return (
                                  <div>{getBoolSelectYN(val.Display)}</div>
                              );
                            },
                          },
                          
                        ]}
                        table_data={this.state.tableData}
                        columnClickedCallback={(col) => {
                          this.useSorter(col);
                        }}
                      />
                    </div>
                  </div>
                </main>
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}