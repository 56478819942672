import React from "react";
import ModalForm from "./ModalForm";
import {isBlank, isOnlyWhitespace, isValidEmail} from "../../util/ValidationUtil";
import Select from "react-select";
import {sweetalert} from "../../App";
import {AiOutlineDown, AiOutlineUp} from "react-icons/ai";
import {isEmptyObject} from "jquery";
import Validator, {ValidationEntry} from "../../validation/Validator";
import {Validators} from "../../validation/Validators";
import {FacilityMgmtBody} from "../../types/Facility";
import {getlabelsFromIDs, getFacilityLabel, getBoolSelectVal, getBoolSelectOptions, buildRow, getLabel, handleCloseModal, hideModal, getReactSelectAriaLabel} from "../../util/FormatUtil";
import {CustomFieldBuilder} from '../../customfields/CustomFieldBuilder' 
import GetField from "../../customfields/GetField";
import CustomInput from "../form/CustomInput";
import CustomSelect from "../form/CustomSelect";
import CustomPhone from "../form/CustomPhone";
import SystemAPI from "../../network/SystemAPI";
import PaymentAPI from "../../network/PaymentAPI";
import { AuthNetEnvironment, HostedForm } from "react-acceptjs";
import Overlay from "../Overlay";
import PhoneInput, {isValidPhoneNumber} from 'react-phone-number-input'


const authData = {
    apiLoginID: process.env.REACT_APP_AUTHORIZE_NET_LOGIN_ID,
    clientKey: process.env.REACT_APP_AUTHORIZE_NET_PUBLIC_CLIENT_KEY
}
const aNetEnv = process.env.REACT_APP_AUTHORIZE_NET_ENVIRONMENT as AuthNetEnvironment

interface FacilityProps{
    selectedFacility
    states
    countries
    onSubmit:(facility) => void
    facilityGroups
    isGlobal
    isAdmin
    pageCustomQuestions?
    services
    commTemplates
    facilityCommTemplateStatusArray
}

interface FacilityState{
    showLoading: boolean;
    selectedFacility?
    isSubmitting?
    facilityArrowDown?
    providerArrowDown?
    rapidTestArrowDown?
    customFieldArrowDown?
    paymentProfileArrowDown?
    newFacilityGroup?
    facilityGroups?
    enabledCommTemplateLabels?
    ANETCustomerProfile?
    useProviderInfo:boolean
    changesMade:boolean
    billToInfoVerified:boolean
}
export class ManageFacilityModal extends React.Component<FacilityProps, FacilityState>{
    public formRef: React.RefObject<ModalForm>;

    constructor(props) {
        super(props);
        this.state = {
            showLoading: false,
            isSubmitting: false,
            facilityArrowDown: false,
            providerArrowDown: false,
            rapidTestArrowDown: false,
            customFieldArrowDown: false,
            paymentProfileArrowDown: false,
            useProviderInfo: false,
            changesMade: false,
            billToInfoVerified: false
        };
        this.formRef = React.createRef()
    }
    
    componentWillReceiveProps(nextProps: Readonly<FacilityProps>, nextContext: any) {
        nextProps = JSON.parse(JSON.stringify(nextProps))
        // console.log('nextProps.selectedFacility', nextProps.selectedFacility)

        if(!isEmptyObject(nextProps.selectedFacility)){

            let serviceIDs = nextProps.selectedFacility.ServiceIDs ? JSON.parse(nextProps.selectedFacility.ServiceIDs) : [];
            let ANETCustomerProfileID = nextProps.selectedFacility.ANETCustomerProfileID;
            let ANETCustomerPaymentProfileID = nextProps.selectedFacility.ANETCustomerPaymentProfileID;

            if(ANETCustomerProfileID && ANETCustomerPaymentProfileID && !isBlank(ANETCustomerProfileID) && !isBlank(ANETCustomerPaymentProfileID) && !isOnlyWhitespace(ANETCustomerPaymentProfileID) && !isOnlyWhitespace(ANETCustomerProfileID) ){
                //get ANET Customer Profile
                PaymentAPI.getCustomerProfile(ANETCustomerProfileID, ANETCustomerPaymentProfileID).then(data => {
                    this.setState({ANETCustomerProfile: data.customerProfile.profile});
                })
            }
            else{
                this.setState({
                    ANETCustomerProfile: {
                        customerProfileId: '',
                        description: '',
                        email: '',
                        paymentProfiles: [
                            {
                                billTo: {
                                    address: '',
                                    city: '',
                                    country: '',
                                    firstName: '',
                                    lastName: '',
                                    phoneNumber: '',
                                    state: '',
                                    zip: ''
                                }, 
                                customerPaymentProfileId: '',
                                payment: {
                                    creditCard: {
                                        cardNumber: '',
                                        cardType: '',
                                        expirationDate: ''
                                    }
                                }
    
                            }
                        ],
                        profileType: 'regular',
                        // shipToList: [
                        //     {
                        //         address: '',
                        //         city: '',
                        //         company: '',
                        //         country: '',
                        //         customerAddressId: '',
                        //         faxNumber: '',
                        //         firstName: '',
                        //         lastName: '',
                        //         phoneNumber: '',
                        //         state: '',
                        //         zip: ''
                        //     }
                        // ]
                    }
                })
            }


            this.setState({
                changesMade: false,
                facilityGroups: nextProps.facilityGroups ? JSON.parse(JSON.stringify(this.props.facilityGroups)) : null,
                selectedFacility:{
                    selectedServices: serviceIDs ? serviceIDs : [],
                    selectedServicesLabel: nextProps.selectedFacility && nextProps.selectedFacility.ServiceIDs ? getlabelsFromIDs(serviceIDs, this.props.services) : '',
                    facilityID: nextProps.selectedFacility && nextProps.selectedFacility.ID ? nextProps.selectedFacility.ID : '',
                    facilityName: nextProps.selectedFacility &&  nextProps.selectedFacility.FacilityName ? nextProps.selectedFacility.FacilityName : '',
                    facilityAbbreviation: nextProps.selectedFacility && nextProps.selectedFacility.FacilityAbbreviation ? nextProps.selectedFacility.FacilityAbbreviation : '',
                    facilityPhone: nextProps.selectedFacility && nextProps.selectedFacility.FacilityPhone ? nextProps.selectedFacility.FacilityPhone : '',
                    facilityEmail: nextProps.selectedFacility && nextProps.selectedFacility.FacilityEmail ? nextProps.selectedFacility.FacilityEmail : '',
                    facilityAddress: nextProps.selectedFacility && nextProps.selectedFacility.FacilityAddress ? nextProps.selectedFacility.FacilityAddress : '',
                    facilityAddressTwo: nextProps.selectedFacility && nextProps.selectedFacility.FacilityAddressTwo ? nextProps.selectedFacility.FacilityAddressTwo : '',
                    facilityCity: nextProps.selectedFacility && nextProps.selectedFacility.FacilityCity ? nextProps.selectedFacility.FacilityCity : '',
                    facilityZip: nextProps.selectedFacility && nextProps.selectedFacility.FacilityZip ? nextProps.selectedFacility.FacilityZip : '',
                    facilityState: nextProps.selectedFacility && nextProps.selectedFacility.FacilityState ? nextProps.selectedFacility.FacilityState : '',
                    facilityCounty: nextProps.selectedFacility && nextProps.selectedFacility.FacilityCounty ? nextProps.selectedFacility.FacilityCounty : '',
                    facilityGroup: nextProps.selectedFacility && nextProps.selectedFacility.FacilityGroup ? nextProps.selectedFacility.FacilityGroup : '',
                    facilityGroupLabel: nextProps.selectedFacility && nextProps.selectedFacility.FacilityGroup ? getFacilityLabel(nextProps.selectedFacility.FacilityGroup, this.props.facilityGroups) : '',
                    providerFirstName: nextProps.selectedFacility && nextProps.selectedFacility.ProviderFirstName ? nextProps.selectedFacility.ProviderFirstName : '',
                    providerLastName: nextProps.selectedFacility && nextProps.selectedFacility.ProviderLastName ? nextProps.selectedFacility.ProviderLastName : '',
                    providerPhone: nextProps.selectedFacility && nextProps.selectedFacility.ProviderPhone ? nextProps.selectedFacility.ProviderPhone : '',
                    providerEmail: nextProps.selectedFacility && nextProps.selectedFacility.ProviderEmail ? nextProps.selectedFacility.ProviderEmail : '',
                    providerAddress: nextProps.selectedFacility && nextProps.selectedFacility.ProviderAddress ? nextProps.selectedFacility.ProviderAddress : '',
                    providerCity: nextProps.selectedFacility && nextProps.selectedFacility.ProviderCity ? nextProps.selectedFacility.ProviderCity : '',
                    providerZip: nextProps.selectedFacility && nextProps.selectedFacility.ProviderZip ? nextProps.selectedFacility.ProviderZip : '',
                    providerState: nextProps.selectedFacility && nextProps.selectedFacility.ProviderState ? nextProps.selectedFacility.ProviderState : '',
                    facilityStateLabel: nextProps.selectedFacility && nextProps.selectedFacility.FacilityState ? getFacilityLabel(nextProps.selectedFacility.FacilityState, this.props.states) : '',
                    providerStateLabel: nextProps.selectedFacility && nextProps.selectedFacility.ProviderState ? getFacilityLabel(nextProps.selectedFacility.ProviderState, this.props.states) : '',
                    CLIA: nextProps.selectedFacility && nextProps.selectedFacility.CLIA ? nextProps.selectedFacility.CLIA : '',
                    reportAddressID: nextProps.selectedFacility && nextProps.selectedFacility.ReportAddressID ? nextProps.selectedFacility.ReportAddressID : '',
                    reportStreetAddress: nextProps.selectedFacility && nextProps.selectedFacility.StreetAddressOne ? nextProps.selectedFacility.StreetAddressOne : '',
                    reportStreetAddressTwo: nextProps.selectedFacility && nextProps.selectedFacility.StreetAddressTwo ? nextProps.selectedFacility.StreetAddressTwo : '',
                    reportCity: nextProps.selectedFacility && nextProps.selectedFacility.City ? nextProps.selectedFacility.City : '',
                    reportZip: nextProps.selectedFacility && nextProps.selectedFacility.Zipcode ? nextProps.selectedFacility.Zipcode : '',
                    reportState: nextProps.selectedFacility && nextProps.selectedFacility.State ? nextProps.selectedFacility.State : '',
                    reportCounty : nextProps.selectedFacility && nextProps.selectedFacility.County ? nextProps.selectedFacility.County : '',
                    reportCountry: nextProps.selectedFacility && nextProps.selectedFacility.Country ? nextProps.selectedFacility.Country : 'United States',
                    reportStateLabel: nextProps.selectedFacility && nextProps.selectedFacility.State ? getFacilityLabel(nextProps.selectedFacility.State, this.props.states) : '',
                    reportHelpEmail: nextProps.selectedFacility && nextProps.selectedFacility.ReportHelpEmail ? nextProps.selectedFacility.ReportHelpEmail : '',
                    reportDirector: nextProps.selectedFacility && nextProps.selectedFacility.ReportDirector ? nextProps.selectedFacility.ReportDirector : '',
                    reportEntityName: nextProps.selectedFacility && nextProps.selectedFacility.ReportEntityName ? nextProps.selectedFacility.ReportEntityName : '',
                    oid: nextProps.selectedFacility && nextProps.selectedFacility.LabID ? nextProps.selectedFacility.LabID : '',
                    facilityGroups: nextProps.facilityGroups ? JSON.parse(JSON.stringify(this.props.facilityGroups)) : null,
                    CustomFieldData: nextProps.selectedFacility && nextProps.selectedFacility.CustomFieldData ? JSON.parse(nextProps.selectedFacility.CustomFieldData) : null,
                    createdDate: nextProps.selectedFacility && nextProps.selectedFacility.CreatedDate ? nextProps.selectedFacility.CreatedDate : null,
                    labInfoID: nextProps.selectedFacility && nextProps.selectedFacility.LabInfoID ? nextProps.selectedFacility.LabInfoID : "",
                    isActive: nextProps.selectedFacility && nextProps.selectedFacility.Active ? nextProps.selectedFacility.Active : "",
                    isActiveLabel: nextProps.selectedFacility && nextProps.selectedFacility.Active ? getBoolSelectVal(nextProps.selectedFacility.Active) : getBoolSelectVal(nextProps.selectedFacility.Active),
                    enabledCommTemplateLabels: nextProps.selectedFacility && nextProps.selectedFacility.ID && serviceIDs && serviceIDs.length > 0 ? this.getEnabledCommTemplateLabels(nextProps.selectedFacility.ID, serviceIDs) : [],
                    ANETMerchantID: nextProps.selectedFacility && nextProps.selectedFacility.ANETMerchantID ? nextProps.selectedFacility.ANETMerchantID : null,
                    ANETCustomerProfileID: nextProps.selectedFacility && nextProps.selectedFacility.ANETCustomerProfileID ? nextProps.selectedFacility.ANETCustomerProfileID : null,
                    ANETCustomerPaymentProfileID: nextProps.selectedFacility && nextProps.selectedFacility.ANETCustomerPaymentProfileID ? nextProps.selectedFacility.ANETCustomerPaymentProfileID : null,
                }
            })
        }
        else{
            this.setState({
                changesMade: false,
                ANETCustomerProfile: {
                    customerProfileId: '',
                    description: '',
                    email: '',
                    paymentProfiles: [
                        {
                            billTo: {
                                address: '',
                                city: '',
                                country: '',
                                firstName: '',
                                lastName: '',
                                phoneNumber: '',
                                state: '',
                                zip: ''
                            }, 
                            customerPaymentProfileId: '',
                            payment: {
                                creditCard: {
                                    cardNumber: '',
                                    cardType: '',
                                    expirationDate: ''
                                }
                            }

                        }
                    ],
                    profileType: 'regular',
                    // shipToList: [
                    //     {
                    //         address: '',
                    //         city: '',
                    //         company: '',
                    //         country: '',
                    //         customerAddressId: '',
                    //         faxNumber: '',
                    //         firstName: '',
                    //         lastName: '',
                    //         phoneNumber: '',
                    //         state: '',
                    //         zip: ''
                    //     }
                    // ]
                },
                facilityGroups: nextProps.facilityGroups ? JSON.parse(JSON.stringify(this.props.facilityGroups)) : null,
                selectedFacility:{
                    selectedServices: [],
                    selectedServicesLabel: '',
                    facilityID: '',
                    facilityName: '',
                    facilityAbbreviation: '',
                    facilityPhone: '',
                    facilityEmail: '',
                    facilityAddress: '',
                    facilityAddressTwo: '',
                    facilityCity: '',
                    facilityZip: '',
                    facilityState: '',
                    facilityCounty: '',
                    facilityGroup: '',
                    facilityGroupLabel: '',
                    providerFirstName: '',
                    providerLastName: '',
                    providerPhone: '',
                    providerEmail: '',
                    providerAddress: '',
                    providerCity: '',
                    providerZip: '',
                    providerState: '',
                    facilityStateLabel: '',
                    providerStateLabel: '',
                    CLIA: '',
                    reportAddressID: '',
                    reportStreetAddress: '',
                    reportStreetAddressTwo: '',
                    reportCity: '',
                    reportZip: '',
                    reportState: '',
                    reportCounty : '',
                    reportCountry: 'United States',
                    reportStateLabel: '',
                    reportHelpEmail: '',
                    reportDirector: '',
                    reportEntityName: '',
                    oid: '',
                    facilityGroups: null,
                    CustomFieldData: null,
                    createdDate: null,
                    labInfoID: '',
                    isActive: '',
                    isActiveLabel: getBoolSelectVal(0),
                    enabledCommTemplateLabels: [],
                    ANETMerchantID: null,
                    ANETCustomerProfileID: null,
                    ANETCustomerPaymentProfileID: null,
                }
            })
        }
    }


    getNewFacilityObj(){
        return {
            ID: this.state.selectedFacility.facilityID ? this.state.selectedFacility.facilityID : null,
            ServiceIDs: this.state.selectedFacility.selectedServices && this.state.selectedFacility.selectedServicesLabel && this.state.selectedFacility.selectedServicesLabel.length > 0 ? this.state.selectedFacility.selectedServicesLabel.map(s => {return s.value}) : null,
            FacilityName: this.state.selectedFacility.facilityName ? this.state.selectedFacility.facilityName.trim() : null,
            FacilityAbbreviation: this.state.selectedFacility.facilityAbbreviation ? this.state.selectedFacility.facilityAbbreviation.trim() : null,
            FacilityPhone: this.state.selectedFacility.facilityPhone ? this.state.selectedFacility.facilityPhone : null,
            FacilityEmail: this.state.selectedFacility.facilityEmail ? this.state.selectedFacility.facilityEmail.trim() : null,
            FacilityAddress: this.state.selectedFacility.facilityAddress ? this.state.selectedFacility.facilityAddress.trim() : null,
            FacilityAddressTwo: this.state.selectedFacility.facilityAddressTwo ? this.state.selectedFacility.facilityAddressTwo.trim() : null,
            FacilityCity: this.state.selectedFacility.facilityCity ? this.state.selectedFacility.facilityCity.trim() : null,
            FacilityZip: this.state.selectedFacility.facilityZip ? this.state.selectedFacility.facilityZip : null,
            FacilityState: this.state.selectedFacility.facilityStateLabel ? this.state.selectedFacility.facilityStateLabel.value : null,
            FacilityCounty: this.state.selectedFacility.facilityCounty ? this.state.selectedFacility.facilityCounty.trim() : null,
            FacilityGroup: this.state.selectedFacility.facilityGroupLabel ? this.state.selectedFacility.facilityGroupLabel.value : null,
            ProviderFirstName: this.state.selectedFacility.providerFirstName ? this.state.selectedFacility.providerFirstName.trim() : null,
            ProviderLastName: this.state.selectedFacility.providerLastName ? this.state.selectedFacility.providerLastName.trim() : null,
            ProviderPhone: this.state.selectedFacility.providerPhone ? this.state.selectedFacility.providerPhone : null,
            ProviderEmail: this.state.selectedFacility.providerEmail ? this.state.selectedFacility.providerEmail.trim() : null,
            ProviderAddress: this.state.selectedFacility.providerAddress ? this.state.selectedFacility.providerAddress.trim() : null,
            ProviderCity: this.state.selectedFacility.providerCity ? this.state.selectedFacility.providerCity.trim() : null,
            ProviderZip: this.state.selectedFacility.providerZip ? this.state.selectedFacility.providerZip : null,
            ProviderState: this.state.selectedFacility.providerStateLabel ? this.state.selectedFacility.providerStateLabel.value : null,
            CLIA: this.state.selectedFacility.CLIA ? this.state.selectedFacility.CLIA.trim() : null,
            ReportAddressID: this.state.selectedFacility.reportAddressID ? this.state.selectedFacility.reportAddressID : null,
            ReportStreetAddress: this.state.selectedFacility.reportStreetAddress ? this.state.selectedFacility.reportStreetAddress.trim() : null,
            ReportStreetAddressTwo: this.state.selectedFacility.reportStreetAddressTwo ? this.state.selectedFacility.reportStreetAddressTwo.trim() : null,
            ReportCity: this.state.selectedFacility.reportCity ? this.state.selectedFacility.reportCity.trim() : null,
            ReportZip: this.state.selectedFacility.reportZip ? this.state.selectedFacility.reportZip : null,
            ReportCounty: this.state.selectedFacility.reportCounty ? this.state.selectedFacility.reportCounty : null,
            ReportCountry: this.state.selectedFacility.reportCountry ? this.state.selectedFacility.reportCountry : null,
            ReportState: this.state.selectedFacility.reportStateLabel ? this.state.selectedFacility.reportStateLabel.value : null,
            ReportHelpEmail: this.state.selectedFacility.reportHelpEmail ? this.state.selectedFacility.reportHelpEmail.trim() : null,
            ReportDirector: this.state.selectedFacility.reportDirector ? this.state.selectedFacility.reportDirector.trim() : null,
            ReportEntityName: this.state.selectedFacility.reportEntityName ? this.state.selectedFacility.reportEntityName.trim() : null,
            Oid: this.state.selectedFacility.oid ? this.state.selectedFacility.oid.trim() : null,
            CustomFieldData: this.state.selectedFacility.CustomFieldData ? this.state.selectedFacility.CustomFieldData : null,
            CreatedDate: this.state.selectedFacility.createdDate ? this.state.selectedFacility.createdDate : new Date(),
            LabInfoID: this.state.selectedFacility.labInfoID ? this.state.selectedFacility.labInfoID : null,
            FacilityGroupID: this.state.selectedFacility.facilityGroup ? this.state.selectedFacility.facilityGroup.ID : null,
            Active: this.state.selectedFacility.isActiveLabel ? this.state.selectedFacility.isActiveLabel.value : false,
            CommTemplateStatusArray: this.state.selectedFacility.enabledCommTemplateLabels && this.state.selectedFacility.enabledCommTemplateLabels.length > 0 ? this.state.selectedFacility.enabledCommTemplateLabels : null,
            ANETMerchantID: this.state.selectedFacility.ANETMerchantID ? this.state.selectedFacility.ANETMerchantID : this.state.ANETCustomerProfile.merchantCustomerId ? this.state.ANETCustomerProfile.merchantCustomerId : null,
            ANETCustomerProfileID: this.state.selectedFacility.ANETCustomerProfileID ? this.state.selectedFacility.ANETCustomerProfileID : this.state.ANETCustomerProfile.customerProfileId ? this.state.ANETCustomerProfile.customerProfileId : null,
            ANETCustomerPaymentProfileID: this.state.selectedFacility.ANETCustomerPaymentProfileID ? this.state.selectedFacility.ANETCustomerPaymentProfileID : this.state.ANETCustomerProfile.paymentProfiles[0].customerPaymentProfileId ? this.state.ANETCustomerProfile.paymentProfiles[0].customerPaymentProfileId : null,
        }
    }

    //Checks if service being assigned is a Rapid service to know whether the Rapid Test Info should be required or not.
    async isServiceRapid(serviceIDs){
        let hasRapid;
        await SystemAPI.serviceIsRapid(serviceIDs).then(res =>{
            //console.log(res)
            //@ts-ignore
            hasRapid = res.IsRapid
        })
        return hasRapid;
    }

    async onSubmit(){
                   
        // let hasRapidTestInService = await this.isServiceRapid(this.state.selectedFacility.selectedServices)
        // let selectedServices = this.props.services.filter(service => this.state.selectedFacility.selectedServices.includes(service.ID));
        // let hasRapidTestInService = selectedServices.some(service => service.IsLabService === 0);
        let validator = new Validator<FacilityMgmtBody>()
            .withComposedValidation("FacilityName", new ValidationEntry(Validators.requireNotBlankValidator("Facility Name")), new ValidationEntry(Validators.requireLength(200, "Facility Name")))
            .withSimpleValidation("FacilityGroup", Validators.requireNonNullValidator("Facility Group"))
            // .withSimpleValidation("ServiceIDs", Validators.requireNonNullValidator("Services"))
            .withComposedValidation("FacilityAbbreviation", new ValidationEntry(Validators.requireNotBlankValidator("Facility Abbreviation")), new ValidationEntry(Validators.requireLength(100, "Facility Abbreviation")))
            .withComposedValidation("FacilityPhone", new ValidationEntry(Validators.requireNonNullValidator("Facility Phone")), new ValidationEntry(Validators.requirePhone("Facility Phone")))
            .withComposedValidation("FacilityEmail", new ValidationEntry(Validators.requireNotBlankValidator("Facility Email")), new ValidationEntry(Validators.requireValidEmail("Facility Email")), new ValidationEntry(Validators.requireLength(200, "Facility Email")))
            .withComposedValidation("FacilityAddress", Validators.requireNotBlankAndLength(100, "Facility Address"))
            if(this.state.selectedFacility.facilityAddressTwo){
                validator = validator.withComposedValidation("FacilityAddressTwo", new ValidationEntry(Validators.requireNotBlankValidator("Facility Address cont")), new ValidationEntry(Validators.requireLength(100, "Facility Address cont")))
            }
            validator = validator.withComposedValidation("FacilityCity", Validators.requireNotBlankAndLength(100, "Facility City"))
            .withComposedValidation("FacilityZip", new ValidationEntry(Validators.requireNotBlankValidator("Facility Zipcode")), new ValidationEntry(Validators.requireZip("Facility Zipcode")))
            .withSimpleValidation("FacilityState", Validators.requireNonNullValidator("Facility State"))
                .withComposedValidation("FacilityCounty", Validators.requireNotBlankAndLength(100, "Facility County"))
                // .withComposedValidation("ProviderFirstName", new ValidationEntry(Validators.requireNotBlankValidator("Provider First Name")), new ValidationEntry(Validators.requireLength(50, "Provider First Name")))
                // .withComposedValidation("ProviderLastName", new ValidationEntry(Validators.requireNotBlankValidator("Provider Last Name")), new ValidationEntry(Validators.requireLength(50, "Provider Last Name")))
                // .withComposedValidation("ProviderPhone", new ValidationEntry(Validators.requireNonNullValidator("Provider Phone")), new ValidationEntry(Validators.requirePhone("Provider Phone")))
                // .withComposedValidation("ProviderEmail", new ValidationEntry(Validators.requireNotBlankValidator("Provider Email")), new ValidationEntry(Validators.requireValidEmail("Provider Email")), new ValidationEntry(Validators.requireLength(200, "Provider Email")))
                // .withComposedValidation("ProviderAddress", new ValidationEntry(Validators.requireNotBlankValidator("Provider Address")), new ValidationEntry(Validators.requireLength(100, "Provider Address")))
                // .withComposedValidation("ProviderCity", new ValidationEntry(Validators.requireNotBlankValidator("Provider City")), new ValidationEntry(Validators.requireLength(100, "Provider City")))
                // .withComposedValidation("ProviderZip", new ValidationEntry(Validators.requireNotBlankValidator("Provider Zipcode")), new ValidationEntry(Validators.requireZip("Provider Zipcode")))
                // .withSimpleValidation("ProviderState", Validators.requireNonNullValidator("Provider State"))
                
        // if(hasRapidTestInService){
        // validator = validator.withComposedValidation("CLIA", new ValidationEntry(Validators.requireNotBlankValidator("CLIA #")), new ValidationEntry(Validators.requireLength(45, "CLIA #")))
        // .withComposedValidation("Oid", new ValidationEntry(Validators.requireNotBlankValidator("OID")), new ValidationEntry(Validators.requireLength(255, "OID")))
        // .withComposedValidation("ReportStreetAddress", new ValidationEntry(Validators.requireNotBlankValidator("Report Street Address")), new ValidationEntry(Validators.requireLength(100, "Report Street Address")))
        // if(this.state.selectedFacility.reportStreetAddressTwo){
        //     validator = validator.withComposedValidation("ReportStreetAddressTwo", new ValidationEntry(Validators.requireNotBlankValidator("Report Street Address cont")), new ValidationEntry(Validators.requireLength(100, "Report Street Address cont")))
        // }
        // validator = validator.withComposedValidation("ReportCity", new ValidationEntry(Validators.requireNotBlankValidator("Report City")), new ValidationEntry(Validators.requireLength(50, "Report City")))
        //     .withComposedValidation("ReportZip", new ValidationEntry(Validators.requireNotBlankValidator("Report Zipcode")), new ValidationEntry(Validators.requireZip("Report Zipcode")))
        //     .withSimpleValidation("ReportState", Validators.requireNonNullValidator("Report State"))
        //     .withComposedValidation("ReportCounty", new ValidationEntry(Validators.requireNotBlankValidator("Report County")), new ValidationEntry(Validators.requireLength(50, "Report County")))
        // if(this.state.selectedFacility.reportHelpEmail){
        //     validator = validator.withComposedValidation("ReportHelpEmail", new ValidationEntry(Validators.requireNotBlankValidator("Report Help Email")), new ValidationEntry(Validators.requireValidEmail("Report Email")), new ValidationEntry(Validators.requireLength(200, "Report Help Email")))
        // }
        // validator = validator.withComposedValidation("ReportDirector", new ValidationEntry(Validators.requireNotBlankValidator("Report Director")), new ValidationEntry(Validators.requireLength(100, "Report Director")))
        //     .withComposedValidation("ReportEntityName", new ValidationEntry(Validators.requireNotBlankValidator("Report Entity Name")), new ValidationEntry(Validators.requireLength(100, "Report Entity Name")))
        // }

        let validationResponse = validator.validate(this.getNewFacilityObj());
        if(!validationResponse.success) {
            return sweetalert.fire({icon: 'error', title: '', text: validationResponse.error});
        }

        let invalid = CustomFieldBuilder.validateCustomFields(this.state.selectedFacility.CustomFieldData, this.props.pageCustomQuestions, this.getNewFacilityObj())

        if(invalid){
            return;
        }

        if(
            (this.state.ANETCustomerProfile.paymentProfiles[0].billTo.firstName || !isBlank(this.state.ANETCustomerProfile.paymentProfiles[0].billTo.firstName) || !isOnlyWhitespace(this.state.ANETCustomerProfile.paymentProfiles[0].billTo.firstName) ||
            this.state.ANETCustomerProfile.paymentProfiles[0].billTo.lastName || !isBlank(this.state.ANETCustomerProfile.paymentProfiles[0].billTo.lastName) || !isOnlyWhitespace(this.state.ANETCustomerProfile.paymentProfiles[0].billTo.lastName) ||
            this.state.ANETCustomerProfile.email || !isBlank(this.state.ANETCustomerProfile.email) || !isOnlyWhitespace(this.state.ANETCustomerProfile.email) ||
            this.state.ANETCustomerProfile.paymentProfiles[0].billTo.phoneNumber || !isBlank(this.state.ANETCustomerProfile.paymentProfiles[0].billTo.phoneNumber) || !isOnlyWhitespace(this.state.ANETCustomerProfile.paymentProfiles[0].billTo.phoneNumber) ||
            this.state.ANETCustomerProfile.paymentProfiles[0].billTo.address || !isBlank(this.state.ANETCustomerProfile.paymentProfiles[0].billTo.address) || !isOnlyWhitespace(this.state.ANETCustomerProfile.paymentProfiles[0].billTo.address) ||
            this.state.ANETCustomerProfile.paymentProfiles[0].billTo.city || !isBlank(this.state.ANETCustomerProfile.paymentProfiles[0].billTo.city) || !isOnlyWhitespace(this.state.ANETCustomerProfile.paymentProfiles[0].billTo.city) ||
            this.state.ANETCustomerProfile.paymentProfiles[0].billTo.state || !isBlank(this.state.ANETCustomerProfile.paymentProfiles[0].billTo.state) || !isOnlyWhitespace(this.state.ANETCustomerProfile.paymentProfiles[0].billTo.state) ||
            this.state.ANETCustomerProfile.paymentProfiles[0].billTo.country || !isBlank(this.state.ANETCustomerProfile.paymentProfiles[0].billTo.country) || !isOnlyWhitespace(this.state.ANETCustomerProfile.paymentProfiles[0].billTo.country) ||
            this.state.ANETCustomerProfile.paymentProfiles[0].billTo.zip || !isBlank(this.state.ANETCustomerProfile.paymentProfiles[0].billTo.zip) || !isOnlyWhitespace(this.state.ANETCustomerProfile.paymentProfiles[0].billTo.zip))
         &&
            (!this.state.ANETCustomerProfile.paymentProfiles[0].payment.creditCard.cardNumber || isBlank(this.state.ANETCustomerProfile.paymentProfiles[0].payment.creditCard.cardNumber) || isOnlyWhitespace(this.state.ANETCustomerProfile.paymentProfiles[0].payment.creditCard.cardNumber) ||
            !this.state.ANETCustomerProfile.paymentProfiles[0].payment.creditCard.cardType || isBlank(this.state.ANETCustomerProfile.paymentProfiles[0].payment.creditCard.cardType) || isOnlyWhitespace(this.state.ANETCustomerProfile.paymentProfiles[0].payment.creditCard.cardType))          
        ){
            sweetalert.fire({
                title: 'Incomplete Bill To Info',
                text: 'Changes may not be saved',
                showCloseButton: false,
                showDenyButton: true,
                showCancelButton: false,
                showConfirmButton: true,
                confirmButtonText: 'Yes, submit',
                denyButtonText: `Go Back`,
            }).then((result) => {
                if(result.isDenied) {
                   return
                }
                else if(result.isConfirmed){
                    this.setState({newFacilityGroup: '', billToInfoVerified: false})
                    this.props.onSubmit(this.getNewFacilityObj())
                }
            })
        }
        else{
            this.setState({newFacilityGroup: '', billToInfoVerified: false, useProviderInfo: false})
            this.props.onSubmit(this.getNewFacilityObj())
        }
        
    }

// fix needed to show sweetalert input on top of modal
// call this before showing SweetAlert:
    fixBootstrapModal() {
        var modalNode = document.querySelector('.modal[tabindex="-1"]');
        if (!modalNode) return;

        modalNode.removeAttribute('tabindex');
        modalNode.classList.add('js-swal-fixed');
    }

// call this before hiding SweetAlert (inside done callback):
    restoreBootstrapModal() {
        var modalNode = document.querySelector('.modal.js-swal-fixed');
        if (!modalNode) return;

        modalNode.setAttribute('tabindex', '-1');
        modalNode.classList.remove('js-swal-fixed');
    }

    async handleAddNewAuthFacilityGroup(){
        this.fixBootstrapModal()

        const { value: authGroupName} = await sweetalert.fire({
            title: 'Enter new facility group',
            input: 'text',
            showCancelButton: true,
            confirmButtonText: 'Add',
            inputValidator: (value) => {
                if (isBlank(value)|| isOnlyWhitespace(value)) {
                    return 'New Facility Group cannot be blank'
                }
                else if (value.length > 200) {
                    return 'New Facility Group must be 200 characters or less'
                }
            }
        })
        if(authGroupName){
            let updateFacilityGroups = JSON.parse(JSON.stringify(this.state.facilityGroups));
            let duplicateFacilityGroupName = updateFacilityGroups.find(f => f.label === authGroupName)
            if(duplicateFacilityGroupName){
                return sweetalert.fire({icon: 'error', title: '', text: 'New Facility Group name cannot be an existing facility group name'});
            }
            else{
                updateFacilityGroups.push({label: authGroupName.trim(), value:authGroupName.trim()})


                this.setState((prevState) => ({
                    facilityGroups: updateFacilityGroups,
                    selectedFacility: {
                        ...prevState.selectedFacility,
                        facilityGroupLabel: {label: authGroupName.trim(), value: authGroupName.trim()}, 
                        facilityGroup: authGroupName
                    } 
                }))
            }
            this.restoreBootstrapModal()
        }

    }

    handleChange(e, stateToSet, stateLabelToSet='', type='input', multi=false,  selectedServiceID=null){
        if(type === 'select'){
            if(multi){
                 //CommTemplate added or removed
                 if(selectedServiceID){

                    let serviceID = selectedServiceID;
                    let enabledCommTemplateLabelsCopy = this.state.selectedFacility && this.state.selectedFacility.enabledCommTemplateLabels ? JSON.parse(JSON.stringify(this.state.selectedFacility.enabledCommTemplateLabels)) : null;

                    //create objectMap for comparison of e array and enabledCommTemplateLabelsCopy
                    const objectMap = new Map();

                    //if all CommTemplates removed from a service, set IsEnabled to null for every object
                    if(!e || e.length < 1){
                        enabledCommTemplateLabelsCopy.forEach(obj => {
                            if(obj.ServiceID === serviceID){
                                obj.IsEnabled = 0
                            }
                        })
                    }
                    //compare e array's value to enabledCommTemplateLabelsCopy's CommTemplateID.  
                    //if the value is present in e then set IsEnabled to 1 (will display)
                    else{
                        e.forEach(obj => {
                            const identifier = obj.value;
                            objectMap.set(identifier, obj)
                        })
                        enabledCommTemplateLabelsCopy.forEach(obj => {
                            const identifier = obj.CommTemplateID;
                            if(obj.ServiceID && obj.ServiceID === serviceID){
                                if(!objectMap.has(identifier)){
                                    obj.IsEnabled = 0;
                                }
                                else if(objectMap.has(identifier)){
                                    obj.IsEnabled = 1;
                                }
                            }
                        });
                    }
                    this.setState((prevState) => ({
                        changesMade: true,
                        selectedFacility: {
                            ...prevState.selectedFacility,
                            enabledCommTemplateLabels: enabledCommTemplateLabelsCopy 
                        }
                    }))
                }


                //service added or removed
                else{
                    let newArray = [];
                    let enabledCommTemplatesLabelsCopy = this.state.selectedFacility && this.state.selectedFacility.enabledCommTemplateLabels ? JSON.parse(JSON.stringify(this.state.selectedFacility.enabledCommTemplateLabels)) : [];
                    if(e){
                        e.map(s => {
                            newArray.push(s.value)
                        })

                        let selectedServiceLength = this.state.selectedFacility.selectedServices ? this.state.selectedFacility.selectedServices.length : 0; 

                         //if added - see which ServiceID was added and create new CommTemplate objects with that ServiceID and IsEnabled = 0
                        if(newArray.length > selectedServiceLength){  
                            for (let i = 0; i < newArray.length; i++) {
                                if(!!enabledCommTemplatesLabelsCopy.find(f => f.ServiceID === newArray[i])){
                                    continue;
                                }
                                else{
                                    this.props.commTemplates.map(ct => {
                                        enabledCommTemplatesLabelsCopy.push({
                                            ID: null,
                                            Name: ct.Name,
                                            FacilityID: this.state.selectedFacility.facilityID ? this.state.selectedFacility.facilityID : null,
                                            ServiceID: newArray[i],
                                            CommTemplateID: ct.ID,
                                            IsEnabled: 0
                                        })
                                    })
                                }
                            }
                        }
                         //if service removed - determine which service is still present and push into new array.
                        else{    
                            let temp = [];
                            for (let i = 0; i < enabledCommTemplatesLabelsCopy.length; i++) {
                                if(newArray.includes(enabledCommTemplatesLabelsCopy[i].ServiceID)){
                                    temp.push(enabledCommTemplatesLabelsCopy[i])
                                } 
                            }
                            enabledCommTemplatesLabelsCopy = temp;
                        }
                    }
                    //if no service selected, set enabledCommTemplatesLabelsCopy to empty array
                    else{
                        enabledCommTemplatesLabelsCopy = [];
                    }

                    this.setState((prevState) => ({
                        changesMade: true,
                        selectedFacility: {
                          ...prevState.selectedFacility,
                          [stateLabelToSet]: e,
                          [stateToSet]: newArray.length > 0 ? newArray : null,
                          enabledCommTemplateLabels: enabledCommTemplatesLabelsCopy 
                        },
                      }));
                }
            }
            else{
                this.setState((prevState) => ({
                  changesMade: true,
                  selectedFacility: {
                    ...prevState.selectedFacility,
                    [stateLabelToSet]: e,
                    [stateToSet]: e.value,
                  },
                }));
            }
        }
        else{
          this.setState((prevState) => ({
            changesMade: true,
            selectedFacility: {
              ...prevState.selectedFacility,
              [stateToSet]: e.target.value,
            },
          }));
        }
    }

    handleDateOnChange(value, state) {
        if (value) {
          this.setState((prevState) => ({
            changesMade: true,
            selectedFacility: {
              ...prevState.selectedFacility,
              [state]: value,
            },
          }));
        } else {
          this.setState((prevState) => ({
            changesMade: true,
            selectedFacility: {
              ...prevState.selectedFacility,
              [state]: null,
            },
          }));
        }
      }

      getEnabledCommTemplateLabels(facilityID, serviceIDs){
        let returnArray = [];
        if(facilityID && serviceIDs && serviceIDs.length > 0){
            for (let i = 0; i < serviceIDs.length; i++) {
                if(!this.props.facilityCommTemplateStatusArray || this.props.facilityCommTemplateStatusArray.length < 1 || !this.props.facilityCommTemplateStatusArray.find(f => f.FacilityID === facilityID) || !this.props.facilityCommTemplateStatusArray.find(f => f.ServiceID === serviceIDs[i])){
                    this.props.commTemplates.map(ct => {
                        returnArray.push({
                            Name: ct.Name,
                            FacilityID: facilityID,
                            ServiceID: serviceIDs[i],
                            CommTemplateID: ct.ID,
                            IsEnabled: 0
                        })
                    })
                }
                else{
                    for (let j = 0; j < this.props.facilityCommTemplateStatusArray.length; j++) {
                        if(this.props.facilityCommTemplateStatusArray[j].FacilityID === facilityID && this.props.facilityCommTemplateStatusArray[j].ServiceID === serviceIDs[i]){
                            returnArray.push({
                                ID: this.props.facilityCommTemplateStatusArray[j].ID,
                                Name: this.props.commTemplates.find(f => f.ID === this.props.facilityCommTemplateStatusArray[j].CommTemplateID).Name,
                                FacilityID: facilityID,
                                ServiceID: serviceIDs[i],
                                CommTemplateID: this.props.facilityCommTemplateStatusArray[j].CommTemplateID,
                                IsEnabled: this.props.facilityCommTemplateStatusArray[j].IsEnabled
                            })
                        }
                        // else if(this.props.facilityCommTemplateStatusArray[j].FacilityID !== facilityID && this.props.facilityCommTemplateStatusArray[j].ServiceID === serviceIDs[i]){
                        //     returnArray.push({
                        //         ID: this.props.facilityCommTemplateStatusArray[j].ID,
                        //         Name: this.props.commTemplates.find(f => f.ID === this.props.facilityCommTemplateStatusArray[j].CommTemplateID).Name,
                        //         FacilityID: facilityID,
                        //         ServiceID: serviceIDs[i],
                        //         CommTemplateID: this.props.facilityCommTemplateStatusArray[j].CommTemplateID,
                        //         IsEnabled: 0
                        //     })
                        // }
                    }
                }
            }
        }

        return returnArray;
      }

      verifyBillToInfo(){
        if(isBlank(this.state.ANETCustomerProfile.paymentProfiles[0].billTo.firstName) || isOnlyWhitespace(this.state.ANETCustomerProfile.paymentProfiles[0].billTo.firstName)){
            return sweetalert.fire({icon: 'error', title: '', text: 'First Name is required'})
        }
        if(isBlank(this.state.ANETCustomerProfile.paymentProfiles[0].billTo.lastName) || isOnlyWhitespace(this.state.ANETCustomerProfile.paymentProfiles[0].billTo.lastName)){
            return sweetalert.fire({icon: 'error', title: '', text: 'Last Name is required'})
        }
        if(isBlank(this.state.ANETCustomerProfile.email) || isOnlyWhitespace(this.state.ANETCustomerProfile.email) || !isValidEmail(this.state.ANETCustomerProfile.email)){
            return sweetalert.fire({icon: 'error', title: '', text: 'Email is required and must be valid'})
        }
        if(isBlank(this.state.ANETCustomerProfile.paymentProfiles[0].billTo.phoneNumber) || isOnlyWhitespace(this.state.ANETCustomerProfile.paymentProfiles[0].billTo.phoneNumber) || !isValidPhoneNumber(this.state.ANETCustomerProfile.paymentProfiles[0].billTo.phoneNumber)){
            return sweetalert.fire({icon: 'error', title: '', text: 'Phone number is required and must be valid'})
        }
        if(isBlank(this.state.ANETCustomerProfile.paymentProfiles[0].billTo.address) || isOnlyWhitespace(this.state.ANETCustomerProfile.paymentProfiles[0].billTo.address)){
            return sweetalert.fire({icon: 'error', title: '', text: 'Address is required'})
        }
        if(isBlank(this.state.ANETCustomerProfile.paymentProfiles[0].billTo.city) || isOnlyWhitespace(this.state.ANETCustomerProfile.paymentProfiles[0].billTo.city)){
            return sweetalert.fire({icon: 'error', title: '', text: 'City is required'})
        }
        if(isBlank(this.state.ANETCustomerProfile.paymentProfiles[0].billTo.state) || isOnlyWhitespace(this.state.ANETCustomerProfile.paymentProfiles[0].billTo.state)){
            return sweetalert.fire({icon: 'error', title: '', text: 'State is required'})
        }
        if(isBlank(this.state.ANETCustomerProfile.paymentProfiles[0].billTo.country) || isOnlyWhitespace(this.state.ANETCustomerProfile.paymentProfiles[0].billTo.country)){
            return sweetalert.fire({icon: 'error', title: '', text: 'Country is required'})
        }
        if(isBlank(this.state.ANETCustomerProfile.paymentProfiles[0].billTo.zip) || isOnlyWhitespace(this.state.ANETCustomerProfile.paymentProfiles[0].billTo.zip) || !/^\d{5}(-\d{4})?(?!-)$/.test(this.state.ANETCustomerProfile.paymentProfiles[0].billTo.zip)){
            return sweetalert.fire({icon: 'error', title: '', text: 'Zipcode is required and must be valid'})
        }
        sweetalert.fire({icon: 'success', title: '', text: 'Bill To Info Successfully Validated'})
        this.setState({billToInfoVerified: true})
      }

      async handleBillToSubmit(response){  
        let description = `${this.state.selectedFacility.providerFirstName}-${this.state.selectedFacility.providerLastName}-${this.state.selectedFacility.facilityName}`;
        this.setState({showLoading: true}, async () => {
            if(this.state.selectedFacility.ANETCustomerProfileID){
                PaymentAPI.updateCustomerProfile(this.state.selectedFacility.ANETMerchantID, this.state.selectedFacility.ANETCustomerProfileID, this.state.selectedFacility.ANETCustomerPaymentProfileID, this.state.ANETCustomerProfile, description, response).then(updatedResponse => {
                    if(!updatedResponse.success){
                        this.setState({showLoading: false})
                        return sweetalert.fire({icon: 'error', title: '', text: 'Error updating Bill To Profile. Please submit form and try again.'})
                    }
                    else{
                        this.setState({changesMade: true, ANETCustomerProfile: updatedResponse.updatedCustomerProfile.profile, showLoading: false})
                        return sweetalert.fire({icon: "success", title: '', text: 'Bill To Profile updated successfully'});
                    }
                })
            }
            else{
                await PaymentAPI.createCustomerProfile(this.state.ANETCustomerProfile, description, response).then(createResponse => {
                    if(!createResponse.success){
                        this.setState({showLoading: false})
                        return sweetalert.fire({icon: 'error', title: '', text: 'Error creating Bill To Profile. Please try again.'})
                    }
                    else{
                        this.setState({changesMade: true, ANETCustomerProfile: createResponse.createdCustomerProfile.profile, showLoading: false})
                        return sweetalert.fire({icon: "success", title: '', text: 'Bill To Profile created successfully'});
                    }
                })
            }
        })
      }

      updateInfoOnly(){
        this.setState({showLoading: true}, async () => {
            PaymentAPI.updateCustomerProfileWithoutCardInfo(this.state.selectedFacility.ANETMerchantID, this.state.selectedFacility.ANETCustomerProfileID, this.state.selectedFacility.ANETCustomerPaymentProfileID, this.state.ANETCustomerProfile).then(updatedResponse => {
                if(!updatedResponse.success){
                    this.setState({showLoading: false})
                    return sweetalert.fire({icon: 'error', title: '', text: 'Error updating Bill To Profile. Please submit form and try again.'})
                }
                else{
                    this.setState({changesMade: true, ANETCustomerProfile: updatedResponse.updatedCustomerProfile.profile, showLoading: false})
                    return sweetalert.fire({icon: "success", title: '', text: 'Bill To Profile updated successfully'
                    });
                }
            })
        })
      }

      clear(){
        this.setState({
            billToInfoVerified: false,
            facilityArrowDown: false,
            providerArrowDown: false,
            rapidTestArrowDown: false,
            customFieldArrowDown: false,
            paymentProfileArrowDown: false,
            changesMade: false,
            useProviderInfo: false,
            ANETCustomerProfile: {
                customerProfileId: '',
                description: '',
                email: '',
                paymentProfiles: [
                    {
                        billTo: {
                            address: '',
                            city: '',
                            country: '',
                            firstName: '',
                            lastName: '',
                            phoneNumber: '',
                            state: '',
                            zip: ''
                        }, 
                        customerPaymentProfileId: '',
                        payment: {
                            creditCard: {
                                cardNumber: '',
                                cardType: '',
                                expirationDate: ''
                            }
                        }

                    }
                ],
                profileType: 'regular',
                shipToList: [
                    {
                        address: '',
                        city: '',
                        company: '',
                        country: '',
                        customerAddressId: '',
                        faxNumber: '',
                        firstName: '',
                        lastName: '',
                        phoneNumber: '',
                        state: '',
                        zip: ''
                    }
                ]
            }
        })
      }

      useProviderInfo(){
        this.setState({useProviderInfo: true})
        if(
            (this.state.ANETCustomerProfile.paymentProfiles[0].billTo.address ||
            this.state.ANETCustomerProfile.paymentProfiles[0].billTo.city ||
            this.state.ANETCustomerProfile.paymentProfiles[0].billTo.country ||
            this.state.ANETCustomerProfile.paymentProfiles[0].billTo.firstName ||
            this.state.ANETCustomerProfile.paymentProfiles[0].billTo.lastName ||
            this.state.ANETCustomerProfile.paymentProfiles[0].billTo.phoneNumber ||
            this.state.ANETCustomerProfile.paymentProfiles[0].billTo.state ||
            this.state.ANETCustomerProfile.paymentProfiles[0].billTo.zip ||
            this.state.ANETCustomerProfile.email) && 
            !this.state.useProviderInfo
        ){
            sweetalert
                .fire({
                title: "Replace Bill To Info?",
                text: "NOTE: For security, credit card info will be cleared.",
                showCloseButton: false,
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: "Yes, replace",
                denyButtonText: `No, go back`,
                })
                .then((result) => {
                if (result.isConfirmed) {
                    this.setState({
                        changesMade: true,
                        useProviderInfo: true,
                        ANETCustomerProfile: {
                            customerProfileId: '',
                            description: '',
                            email: this.state.selectedFacility ? this.state.selectedFacility.providerEmail: '',
                            paymentProfiles: [
                                {
                                    billTo: {
                                        address: this.state.selectedFacility ? this.state.selectedFacility.providerAddress: '',
                                        city: this.state.selectedFacility ? this.state.selectedFacility.providerCity: '',
                                        country: 'United States',
                                        firstName: this.state.selectedFacility ? this.state.selectedFacility.providerFirstName: '',
                                        lastName: this.state.selectedFacility ? this.state.selectedFacility.providerLastName: '',
                                        phoneNumber: this.state.selectedFacility ? this.state.selectedFacility.providerPhone: '',
                                        state: this.state.selectedFacility ? this.state.selectedFacility.providerState: '',
                                        zip: this.state.selectedFacility ? this.state.selectedFacility.providerZip: ''
                                    }, 
                                    customerPaymentProfileId: '',
                                    payment: {
                                        creditCard: {
                                            cardNumber: '',
                                            cardType: '',
                                            expirationDate: ''
                                        }
                                    }
            
                                }
                            ],
                            profileType: 'regular',
                            shipToList: [
                                {
                                    address: '',
                                    city: '',
                                    company: '',
                                    country: '',
                                    customerAddressId: '',
                                    faxNumber: '',
                                    firstName: '',
                                    lastName: '',
                                    phoneNumber: '',
                                    state: '',
                                    zip: ''
                                }
                            ]
                        }
                    })
                    return
                }
                else if(result.isDenied){
                    this.setState({useProviderInfo: false})
                    return
                }
            });
        }
        else if(!this.state.useProviderInfo){
            this.setState({
                changesMade: true,
                useProviderInfo: true,
                ANETCustomerProfile: {
                    customerProfileId: '',
                    description: '',
                    email: this.state.selectedFacility ? this.state.selectedFacility.providerEmail: '',
                    paymentProfiles: [
                        {
                            billTo: {
                                address: this.state.selectedFacility ? this.state.selectedFacility.providerAddress: '',
                                city: this.state.selectedFacility ? this.state.selectedFacility.providerCity: '',
                                country: 'United States',
                                firstName: this.state.selectedFacility ? this.state.selectedFacility.providerFirstName: '',
                                lastName: this.state.selectedFacility ? this.state.selectedFacility.providerLastName: '',
                                phoneNumber: this.state.selectedFacility ? this.state.selectedFacility.providerPhone: '',
                                state: this.state.selectedFacility ? this.state.selectedFacility.providerState: '',
                                zip: this.state.selectedFacility ? this.state.selectedFacility.providerZip: ''
                            }, 
                            customerPaymentProfileId: '',
                            payment: {
                                creditCard: {
                                    cardNumber: '',
                                    cardType: '',
                                    expirationDate: ''
                                }
                            }
    
                        }
                    ],
                    profileType: 'regular',
                    shipToList: [
                        {
                            address: '',
                            city: '',
                            company: '',
                            country: '',
                            customerAddressId: '',
                            faxNumber: '',
                            firstName: '',
                            lastName: '',
                            phoneNumber: '',
                            state: '',
                            zip: ''
                        }
                    ]
                }
            })
        }
        else{
            this.setState({useProviderInfo: false})
            if(this.state.selectedFacility && this.state.selectedFacility.ANETCustomerProfileID){
                this.setState({showLoading: true})
                PaymentAPI.getCustomerProfile(this.state.selectedFacility.ANETCustomerProfileID, this.state.selectedFacility.ANETCustomerPaymentProfileID).then(data => {
                    this.setState({ANETCustomerProfile: data.customerProfile.profile, showLoading: false});
                })
            }
            else{
                this.clear()
            }
            return
        }
      }





    public static readonly ID = "newfacility"
    render(): React.ReactElement | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {

        // console.log('MgFacModal props', this.props)
        // console.log('MgFacModal state', this.state)        

        let selectedServices = this.state.selectedFacility?.selectedServices ? this.props.services.filter(service => this.state.selectedFacility.selectedServices?.includes(service.ID)) : null;
        let showRapidTestInfo = selectedServices ? selectedServices.some(service => service.IsLabService === 0) : null;

        return (
            <React.Fragment>
                <Overlay show_loading={this.state.showLoading}  zIndex={100003} />
                <div className="modal fade form_modal" aria-label="Facility Management" id={ManageFacilityModal.ID} tabIndex={-1} role="dialog"
                     aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-xl" role="document">
                        <div className="modal-content">
                            <div className="modal-body p-0 m-0">
                                <div className="container-fluid">
                                    <div className={"row"}>
                                        <div className="col-12 pt-2">
                                            <div className="card mb-2">
                                                <div className="card-header verlag-bold">
                                                    <div className="row">
                                                        <div className="col-10">
                                                            <h4>
                                                                Facility Editing
                                                            </h4>
                                                        </div>
                                                        <div className="col-2">
                                                        <button  style={{outline: 'none'}} type="button" className="close pr-4"
                                                                aria-label="Close" onClick={() => {
                                                                    if(this.state.changesMade){
                                                                        sweetalert.fire({
                                                                            title: 'Are you sure you want to close without saving?',
                                                                            text: 'Changes may not be saved.',
                                                                            showCloseButton: false,
                                                                            showDenyButton: true,
                                                                            showCancelButton: false,
                                                                            showConfirmButton: true,
                                                                            confirmButtonText: 'Yes, close',
                                                                            denyButtonText: `Go Back`,
                                                                        }).then((result) => {
                                                                            if (result.isConfirmed){
                                                                                hideModal(ManageFacilityModal.ID)
                                                                                this.clear()
                                                                            }
                                                                            else if(result.isDenied) {
                                                                               return
                                                                            }
                                                                        })
                                                                    }
                                                                    else{
                                                                        hideModal(ManageFacilityModal.ID)
                                                                        this.clear()
                                                                    }
                                                                    
                                                                }}>
                                                                
                                                            <span aria-hidden="true" style={{fontSize: '1.5em', fontWeight: 'bold'}}>&times;</span>
                                                        </button>                                                             
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-body">
                                                    <div className="card mb-2">
                                                        <button className="card-header verlag-bold accordionButton w-100" data-toggle="collapse" data-target="#facilityCollapse" onClick={() => this.setState({facilityArrowDown: !this.state.facilityArrowDown})} aria-expanded="false" aria-controls="facilityCollapse">
                                                            <div className="row justify-content-between px-3">
                                                                <div className="">
                                                                    <h4>
                                                                        Facility
                                                                    </h4>
                                                                </div>
                                                                <div className="col-2">
                                                                    {this.state.facilityArrowDown ? (<AiOutlineUp className={'float-right'} size={30}  data-toggle="collapse" data-target="#facilityCollapse"  role="button" aria-expanded="false" aria-controls="facilityCollapse" />) : (<AiOutlineDown className={'float-right'} size={30} data-toggle="collapse" data-target="#facilityCollapse" role="button" aria-expanded="false" aria-controls="facilityCollapse" />)}
                                                                </div>
                                                            </div> 
                                                        </button>
                                                        <div className={"card-body collapse"} id={'facilityCollapse'}>
                                                            <div className="row">
                                                                <div className="col-12 col-lg-6">
                                                                    <CustomInput fromFilterPage={false} value={this.state.selectedFacility?.facilityName} label={"Facility Name"} tooltip={"Facility Name"} handleChange={(e) => this.handleChange(e, 'facilityName')} maxLength={200} />                                                         
                                                                    <div className={"form-group row"}>
                                                                        <div className={"col-12 col-md-4"} data-toggle={'tooltip'} data-placement={'top'} title={'Facility Group'}>
                                                                            <label htmlFor={'FacilityGroup'} style={{fontWeight: 'bold', paddingTop: '0.5em', fontSize: "0.8em"}}>{'Facility Group'}</label>
                                                                        </div>
                                                                        <div className={"col-12 col-md-8 pl-md-0 pr-md-1"} id={'FacilityGroup'}>
                                                                            <div className="row">
                                                                                <div className={this.props.isGlobal || this.props.isAdmin ? "col-12 col-md-7" : "col-12"}>
                                                                                    <Select
                                                                                        isSearchable={true}
                                                                                        placeholder={"Please select..."}
                                                                                        aria-label={getReactSelectAriaLabel("Facility Group", this.state.selectedFacility?.facilityGroupLabel)}
                                                                                        onChange={(e)=>
                                                                                            this.setState((prevState) => ({
                                                                                                selectedFacility: {
                                                                                                ...prevState.selectedFacility,
                                                                                                facilityGroupLabel: e,
                                                                                                facilityGroup: e.value
                                                                                                },
                                                                                                changesMade: true,
                                                                                            }))
                                                                                        }
                                                                                        className={"state_select"}
                                                                                        options={this.state.facilityGroups}
                                                                                        value={this.state.selectedFacility?.facilityGroupLabel}
                                                                                    />
                                                                                </div>
                                                                                <div className={this.props.isGlobal || this.props.isAdmin ? "col-12 col-md-5 mt-2 mt-md-0 pl-md-0" : "d-none"} data-toggle={"tooltip"} data-placement={"top"} title={"This button will open an input field below to create a new facility group"}>
                                                                                    <button className={"btn btn-success w-100 h-100 font-weight-bold"} onClick={() => this.handleAddNewAuthFacilityGroup()}>New Group</button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <CustomInput fromFilterPage={false} value={this.state.selectedFacility?.facilityAbbreviation} label={"Facility Abbreviation"} tooltip={"Facility Abbreviation"} handleChange={(e) => this.handleChange(e, 'facilityAbbreviation')} maxLength={50} />
                                                                    <CustomPhone fromFilterPage={false} value={this.state.selectedFacility?.facilityPhone} label={"Facility Phone"} tooltip={`The phone number of the Facility`} handleDateOnChange={(e) => this.handleDateOnChange(e, "facilityPhone")} />
                                                                    <CustomInput fromFilterPage={false} value={this.state.selectedFacility?.facilityEmail} label={"Facility Email"} tooltip={"Facility Email"} handleChange={(e) => this.handleChange(e, 'facilityEmail')} maxLength={100} />                                                         
                                                                    <CustomInput fromFilterPage={false} value={this.state.selectedFacility?.facilityAddress} label={"Facility Street Address"} tooltip={"Facility Street Address"} handleChange={(e) => this.handleChange(e, 'facilityAddress')} maxLength={100} />                                                         
                                                                    <CustomInput fromFilterPage={false} value={this.state.selectedFacility?.facilityAddressTwo} label={"Facility Street Address cont."} tooltip={"Facility Street Address cont."} handleChange={(e) => this.handleChange(e, 'facilityAddressTwo')} maxLength={100} />                                                         
                                                                </div>
                                                                <div className="col-12 col-lg-6">
                                                                    <CustomInput fromFilterPage={false} value={this.state.selectedFacility?.facilityCity} label={"Facility City"} tooltip={"Facility City"} handleChange={(e) => this.handleChange(e, 'facilityCity')} maxLength={100} />                                                         
                                                                    <CustomInput fromFilterPage={false} value={this.state.selectedFacility?.facilityZip} label={"Facility Zip"} tooltip={"Facility Zip"} handleChange={(e) => this.handleChange(e, 'facilityZip')} maxLength={50} />                                                         
                                                                    <CustomSelect fromFilterPage={false} value={this.state.selectedFacility?.facilityStateLabel} label={"Facility State"} tooltip={"Facility State"} handleChange={(e) => this.handleChange(e, "facilityState", "facilityStateLabel", "select")} options={this.props.states} />
                                                                    <CustomInput fromFilterPage={false} value={this.state.selectedFacility?.facilityCounty} label={"Facility County"} tooltip={"Facility County"} handleChange={(e) => this.handleChange(e, 'facilityCounty')} maxLength={100} />                                                         
                                                                    <CustomSelect fromFilterPage={false} value={this.state.selectedFacility?.isActiveLabel} label={"Is Active?"} tooltip={"Is Active?"} handleChange={(e) => this.handleChange(e, "isActive", "isActiveLabel", "select")} options={getBoolSelectOptions()} />
                                                                    {/* <CustomSelect fromFilterPage={false} value={this.state.selectedFacility?.selectedServicesLabel} label={"Services"} tooltip={"Services"} handleChange={(e) => this.handleChange(e, "selectedServices", "selectedServicesLabel", "select", true)} options={this.props.services.map(s => {return {label: s.Name, value: s.ID}})} isMulti={true} /> */}
                                                                    
                                                                </div>
                                                            </div>
                                                            {this.state.selectedFacility && this.state.selectedFacility.selectedServicesLabel && this.state.selectedFacility.selectedServicesLabel.length > 0 && (
                                                            <div className={"form-group row"}>
                                                                <div className={"col-12 text-center"} data-toggle={'tooltip'} data-placement={'top'} title={'Communications'}>
                                                                    <label htmlFor={'Communications'} style={{fontWeight: 'bold', paddingTop: '1.5em', fontSize: '1.2rem'}}>{'Communications'}</label>
                                                                </div>
                                                            </div>
                                                            )}
                                                            
                                                            {this.state.selectedFacility && this.state.selectedFacility.selectedServicesLabel && this.state.selectedFacility.selectedServicesLabel.length > 0 && this.state.selectedFacility.selectedServicesLabel.map((ssl, index) => (
                                                                <CustomSelect fromFilterPage={false} value={this.state.selectedFacility.enabledCommTemplateLabels && this.state.selectedFacility.enabledCommTemplateLabels.length > 0 ? this.state.selectedFacility.enabledCommTemplateLabels.filter(f => f.ServiceID === ssl.value && f.IsEnabled).map(l => { return {label: l.Name.includes('WalkinEmail') ? 'Submission Email' : l.Name.includes('WalkinText') ? 'Submission Text' :  l.Name.replace(/([A-Z])/g, ' $1').trim(), value: l.CommTemplateID}}) : []}
                                                                label={`${ssl.label}`} tooltip={`Enabled Communication Templates for ${ssl.label}`} handleChange={(e) => this.handleChange(e, "", "", "select", true, ssl.value)} options={this.props.commTemplates.map(s => {return {label: s.Name.includes('WalkinEmail') ? 'Submission Email' : s.Name.includes('WalkinText') ? 'Submission Text' : s.Name.replace(/([A-Z])/g, ' $1').trim(), value: s.ID}})} isMulti={true} />
                                                            ))}
                                                        </div>
                                                    </div>


                                                    {/* <div className="card mb-2">
                                                        <div className="card-header verlag-bold" data-toggle="collapse" data-target="#providerCollapse" onClick={() => this.setState({providerArrowDown: !this.state.providerArrowDown})} role="button" aria-expanded="false" aria-controls="providerCollapse">
                                                            <div className="row">
                                                                <div className="col-10">
                                                                    <h4>
                                                                        Provider
                                                                    </h4>
                                                                </div>
                                                                <div className="col-2">
                                                                    {this.state.providerArrowDown ? (<AiOutlineUp className={'float-right'} size={30}  data-toggle="collapse" data-target="#providerCollapse"  role="button" aria-expanded="false" aria-controls="providerCollapse" />) : (<AiOutlineDown className={'float-right'} size={30} data-toggle="collapse" data-target="#providerCollapse" role="button" aria-expanded="false" aria-controls="providerCollapse" />)}                                                          
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={"card-body collapse"} id={'providerCollapse'}>
                                                            <div className="row">
                                                                <div className="col-12 col-lg-6">
                                                                    <CustomInput fromFilterPage={false} value={this.state.selectedFacility?.providerFirstName} label={"Provider First Name"} tooltip={"Provider First Name"} handleChange={(e) => this.handleChange(e, 'providerFirstName')} maxLength={50} />                                                         
                                                                    <CustomInput fromFilterPage={false} value={this.state.selectedFacility?.providerLastName} label={"Provider Last Name"} tooltip={"Provider Last Name"} handleChange={(e) => this.handleChange(e, 'providerLastName')} maxLength={50} />   
                                                                    <CustomPhone fromFilterPage={false} value={this.state.selectedFacility?.providerPhone} label={"Provider Phone"} tooltip={`The phone number of the Provider`} handleDateOnChange={(e) => this.handleDateOnChange(e, "providerPhone")} />
                                                                    <CustomInput fromFilterPage={false} value={this.state.selectedFacility?.providerEmail} label={"Provider Email"} tooltip={"Provider Email"} handleChange={(e) => this.handleChange(e, 'providerEmail')} maxLength={100} />                                                         
                                                                </div>
                                                                <div className="col-12 col-lg-6">
                                                                    <CustomInput fromFilterPage={false} value={this.state.selectedFacility?.providerAddress} label={"Provider Street Address"} tooltip={"Provider Street Address"} handleChange={(e) => this.handleChange(e, 'providerAddress')} maxLength={100} />                                                         
                                                                    <CustomInput fromFilterPage={false} value={this.state.selectedFacility?.providerCity} label={"Provider City"} tooltip={"Provider City"} handleChange={(e) => this.handleChange(e, 'providerCity')} maxLength={50} />                                                         
                                                                    <CustomInput fromFilterPage={false} value={this.state.selectedFacility?.providerZip} label={"Provider Zip"} tooltip={"Provider Zip"} handleChange={(e) => this.handleChange(e, 'providerZip')} maxLength={50} />                                                         
                                                                    <CustomSelect fromFilterPage={false} value={this.state.selectedFacility?.providerStateLabel} label={"Provider State"} tooltip={"Provider State"} handleChange={(e) => this.handleChange(e, "providerState", "providerStateLabel", "select")} options={this.props.states} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> */}

                                                    {/* <div className="card mb-2">
                                                        <div className="card-header verlag-bold"  data-toggle="collapse" data-target="#resultDetailsCollapse" onClick={() => this.setState({rapidTestArrowDown: !this.state.rapidTestArrowDown})} role="button" aria-expanded="false" aria-controls="resultDetailsCollapse">
                                                            <h4>Rapid Test Reporting
                                                                {this.state.rapidTestArrowDown ? (<AiOutlineUp className={'float-right'} size={30}  data-toggle="collapse" data-target="#resultDetailsCollapse"  role="button" aria-expanded="false" aria-controls="resultDetailsCollapse" />) : (<AiOutlineDown className={'float-right'} size={30} data-toggle="collapse" data-target="#resultDetailsCollapse" role="button" aria-expanded="false" aria-controls="resultDetailsCollapse" />)}
                                                            </h4>
                                                       </div>
                                                        <div className={"card-body collapse"} id={'resultDetailsCollapse'}>
                                                            <h4 className={"mb-3 text-danger"} style={{fontSize: '1rem'}}>*To be completed if Facility will be providing Rapid Test Services</h4>
                                                            <div className="row">
                                                                <div className="col-12 col-lg-6">
                                                                    <CustomInput fromFilterPage={false} value={this.state.selectedFacility?.CLIA} label={"CLIA"} tooltip={"CLIA"} handleChange={(e) => this.handleChange(e, 'CLIA')} maxLength={45} />                                                         
                                                                    <CustomInput fromFilterPage={false} value={this.state.selectedFacility?.oid} label={"Unique OID"} tooltip={"Unique OID allocated to this facility"} handleChange={(e) => this.handleChange(e, 'oid')} maxLength={255} />                                                         
                                                                    <CustomInput fromFilterPage={false} value={this.state.selectedFacility?.reportStreetAddress} label={"Report Street Address"} tooltip={"Report Street Address"} handleChange={(e) => this.handleChange(e, 'reportStreetAddress')} maxLength={100} />                                                         
                                                                    <CustomInput fromFilterPage={false} value={this.state.selectedFacility?.reportStreetAddressTwo} label={"Report Street Address cont."} tooltip={"Report Street Address cont."} handleChange={(e) => this.handleChange(e, 'reportStreetAddressTwo')} maxLength={100} />                                                         
                                                                    <CustomInput fromFilterPage={false} value={this.state.selectedFacility?.reportCity} label={"Report City"} tooltip={"Report City"} handleChange={(e) => this.handleChange(e, 'reportCity')} maxLength={50} />  
                                                                    <CustomInput fromFilterPage={false} value={this.state.selectedFacility?.reportZip} label={"Report Zip"} tooltip={"Report Zip"} handleChange={(e) => this.handleChange(e, 'reportZip')} maxLength={50} />                                                        
                                                                </div>
                                                                <div className="col-12 col-lg-6">                                          
                                                                    <CustomSelect fromFilterPage={false} value={this.state.selectedFacility?.reportStateLabel} label={"Report State"} tooltip={"Report State"} handleChange={(e) => this.handleChange(e, "reportState", "reportStateLabel", "select")} options={this.props.states} />
                                                                    <CustomInput fromFilterPage={false} value={this.state.selectedFacility?.reportCounty} label={"Report County"} tooltip={"Report County"} handleChange={(e) => this.handleChange(e, 'reportCounty')} maxLength={50} />                                                         
                                                                    <CustomInput fromFilterPage={false} value={this.state.selectedFacility?.reportHelpEmail} label={"Report Help Email (Optional)"} tooltip={"Report Help Email (Optional)"} handleChange={(e) => this.handleChange(e, 'reportHelpEmail')} maxLength={100} />                                                         
                                                                    <CustomInput fromFilterPage={false} value={this.state.selectedFacility?.reportDirector} label={"Report Director"} tooltip={"Report Director"} handleChange={(e) => this.handleChange(e, 'reportDirector')} maxLength={100} />                                                         
                                                                    <CustomInput fromFilterPage={false} value={this.state.selectedFacility?.reportEntityName} label={"Report Entity Name"} tooltip={"Report Entity Name"} handleChange={(e) => this.handleChange(e, 'reportEntityName')} maxLength={100} />                                                         
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> */}

                                                    {/* <div className="card mb-2">
                                                        <div className="card-header verlag-bold"  data-toggle="collapse" data-target="#paymentProfileCollapse" onClick={() => this.setState({paymentProfileArrowDown: !this.state.paymentProfileArrowDown})} role="button" aria-expanded="false" aria-controls="paymentProfileCollapse">
                                                            <h4>Payment Profile
                                                                {this.state.paymentProfileArrowDown ? (<AiOutlineUp className={'float-right'} size={30}  data-toggle="collapse" data-target="#paymentProfileCollapse"  role="button" aria-expanded="false" aria-controls="paymentProfileCollapse" />) : (<AiOutlineDown className={'float-right'} size={30} data-toggle="collapse" data-target="#paymentProfileCollapse" role="button" aria-expanded="false" aria-controls="paymentProfileCollapse" />)}
                                                            </h4>
                                                       </div>
                                                        <div className={"card-body collapse"} id={'paymentProfileCollapse'}>
                                                                {this.state.ANETCustomerProfile && this.state.ANETCustomerProfile.paymentProfiles && this.state.ANETCustomerProfile.paymentProfiles.length > 0 && this.state.ANETCustomerProfile.paymentProfiles.map((val, index) => {
                                                                    return (
                                                                        <>
                                                                            <div className={"form-group row"}>
                                                                                <div className={"col-12 pr-0"} data-toggle={'tooltip'} data-placement={'top'} title={'BillTo'}>
                                                                                    <label htmlFor={'BillTo'} style={{fontWeight: 'bold', fontSize: '1.2rem'}}>{index > 1 ? `Bill To Information: ${index + 1}` : 'Bill To Information'}</label>
                                                                                    <div className="form-check float-right" style={{paddingTop: '0.25rem'}}>
                                                                                        <input className="form-check-input" type="checkbox" checked={this.state.useProviderInfo ? true : false} id="useProviderInfoCheck"  onChange={() => this.useProviderInfo()} />
                                                                                        <label className="form-check-label" htmlFor="useProviderInfoCheck">
                                                                                            Use Provider Information
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="col-12 col-lg-6">
                                                                                    {buildRow("First Name",
                                                                                    <input 
                                                                                        className={"form-control"}
                                                                                        autoComplete={"off"}
                                                                                        type={"search"}
                                                                                        name={`BillToFirstName${index+1}`}
                                                                                        onChange={(e)=>{
                                                                                            this.setState( (prevState) => {
                                                                                                
                                                                                                const updatedPaymentProfiles = [...prevState.ANETCustomerProfile.paymentProfiles];
                                                                                                updatedPaymentProfiles[index] = {
                                                                                                    ...updatedPaymentProfiles[index],
                                                                                                    billTo: {
                                                                                                        ...updatedPaymentProfiles[index].billTo,
                                                                                                        firstName: e.target.value
                                                                                                    }
                                                                                                }
                                                                                                
                                                                                                return {
                                                                                                    changesMade: true,
                                                                                                    billToInfoVerified: false,
                                                                                                    ANETCustomerProfile: {
                                                                                                        ...prevState.ANETCustomerProfile,
                                                                                                        paymentProfiles: updatedPaymentProfiles
                                                                                                    }
                                                                                                }
                                                                                            })
                                                                                        }}
                                                                                        value={val.billTo.firstName}
                                                                                    />, 'Card holder first name'
                                                                                    )} 
                                                                                    {buildRow("Last Name",
                                                                                        <input 
                                                                                            className={"form-control"}
                                                                                            autoComplete={"off"}
                                                                                            type={"search"}
                                                                                            name={`BillToLastName${index+1}`}
                                                                                            onChange={(e)=>{
                                                                                                this.setState( (prevState) => {
                                                                                                    const updatedPaymentProfiles = [...prevState.ANETCustomerProfile.paymentProfiles];
                                                                                                    updatedPaymentProfiles[index] = {
                                                                                                        ...updatedPaymentProfiles[index],
                                                                                                        billTo: {
                                                                                                            ...updatedPaymentProfiles[index].billTo,
                                                                                                            lastName: e.target.value
                                                                                                        }
                                                                                                    }
                                                                                                    
                                                                                                    return {
                                                                                                        changesMade: true,
                                                                                                        billToInfoVerified: false,
                                                                                                        ANETCustomerProfile: {
                                                                                                            ...prevState.ANETCustomerProfile,
                                                                                                            paymentProfiles: updatedPaymentProfiles
                                                                                                        }
                                                                                                    }
                                                                                                })
                                                                                            }}
                                                                                            value={val.billTo.lastName}
                                                                                        />, 'Card holder last name'
                                                                                    )}
                                                                                    {buildRow("Email",
                                                                                        <input className={"form-control"}
                                                                                            autoComplete={"off"}
                                                                                            type={"search"}
                                                                                            name={"CardHolderEmail"}
                                                                                            onChange={(e)=>{
                                                                                                this.setState( (prevState) => ({
                                                                                                    changesMade: true,
                                                                                                    billToInfoVerified: false,
                                                                                                    ANETCustomerProfile: {
                                                                                                        ...prevState.ANETCustomerProfile,
                                                                                                        email: e.target.value
                                                                                                    }
                                                                                                }))
                                                                                            }}
                                                                                            value={this.state.ANETCustomerProfile.email}
                                                                                        />
                                                                                    , 'Card holder email'
                                                                                    )}
                                                                                    {buildRow("Phone Number",
                                                                                        <PhoneInput
                                                                                            name={`BillToPhoneNumber${index+1}`}
                                                                                            id={'Phone Number'}
                                                                                            placeholder="Enter phone number"
                                                                                            value={val.billTo.phoneNumber}
                                                                                            defaultCountry="US"
                                                                                            onChange={(e)=>{
                                                                                                this.setState( (prevState) => {
                                                                                                    const updatedPaymentProfiles = [...prevState.ANETCustomerProfile.paymentProfiles];
                                                                                                    updatedPaymentProfiles[index] = {
                                                                                                        ...updatedPaymentProfiles[index],
                                                                                                        billTo: {
                                                                                                            ...updatedPaymentProfiles[index].billTo,
                                                                                                            phoneNumber: e
                                                                                                        }
                                                                                                    }
                                                                                                    
                                                                                                    return {
                                                                                                        changesMade: true,
                                                                                                        billToInfoVerified: false,
                                                                                                        ANETCustomerProfile: {
                                                                                                            ...prevState.ANETCustomerProfile,
                                                                                                            paymentProfiles: updatedPaymentProfiles
                                                                                                        }
                                                                                                    }
                                                                                                })
                                                                                            }}
                                                                                            
                                                                                        />, 'Card holder phone number'
                                                                                    )}
                                                                                    {buildRow("Address",
                                                                                        <input 
                                                                                            className={"form-control"}
                                                                                            autoComplete={"off"}
                                                                                            type={"search"}
                                                                                            name={`BillToAddress${index+1}`}
                                                                                            onChange={(e)=>{
                                                                                                this.setState( (prevState) => {
                                                                                                    const updatedPaymentProfiles = [...prevState.ANETCustomerProfile.paymentProfiles];
                                                                                                    updatedPaymentProfiles[index] = {
                                                                                                        ...updatedPaymentProfiles[index],
                                                                                                        billTo: {
                                                                                                            ...updatedPaymentProfiles[index].billTo,
                                                                                                            address: e.target.value
                                                                                                        }
                                                                                                    }
                                                                                                    
                                                                                                    return {
                                                                                                        changesMade: true,
                                                                                                        billToInfoVerified: false,
                                                                                                        ANETCustomerProfile: {
                                                                                                            ...prevState.ANETCustomerProfile,
                                                                                                            paymentProfiles: updatedPaymentProfiles
                                                                                                        }
                                                                                                    }
                                                                                                })
                                                                                            }}
                                                                                            value={val.billTo.address}
                                                                                        />, 'Card holder address'
                                                                                    )}
                                                                                    {buildRow("City",
                                                                                        <input 
                                                                                            className={"form-control"}
                                                                                            autoComplete={"off"}
                                                                                            type={"search"}
                                                                                            name={`BillToCity${index+1}`}
                                                                                            onChange={(e)=>{
                                                                                                this.setState( (prevState) => {
                                                                                                    const updatedPaymentProfiles = [...prevState.ANETCustomerProfile.paymentProfiles];
                                                                                                    updatedPaymentProfiles[index] = {
                                                                                                        ...updatedPaymentProfiles[index],
                                                                                                        billTo: {
                                                                                                            ...updatedPaymentProfiles[index].billTo,
                                                                                                            city: e.target.value
                                                                                                        }
                                                                                                    }
                                                                                                    
                                                                                                    return {
                                                                                                        changesMade: true,
                                                                                                        billToInfoVerified: false,
                                                                                                        ANETCustomerProfile: {
                                                                                                            ...prevState.ANETCustomerProfile,
                                                                                                            paymentProfiles: updatedPaymentProfiles
                                                                                                        }
                                                                                                    }
                                                                                                })
                                                                                            }}
                                                                                            value={val.billTo.city}
                                                                                        />, 'Card holder city'
                                                                                    )}
                                                                                </div>
                                                                                <div className="col-12 col-lg-6">
                                                                                    {buildRow("State",
                                                                                    <Select
                                                                                        name={`BillToState${index+1}`}
                                                                                        isSearchable={true}
                                                                                        placeholder={"Please Select..."}
                                                                                        noOptionsMessage={() => "No option"}
                                                                                        value={ getLabel(val.billTo.state, this.props.states) }
                                                                                        onChange={(e)=>{
                                                                                            this.setState( (prevState) => {
                                                                                                const updatedPaymentProfiles = [...prevState.ANETCustomerProfile.paymentProfiles];
                                                                                                updatedPaymentProfiles[index] = {
                                                                                                    ...updatedPaymentProfiles[index],
                                                                                                    billTo: {
                                                                                                        ...updatedPaymentProfiles[index].billTo,
                                                                                                        state: e.value
                                                                                                    }
                                                                                                }
                                                                                                
                                                                                                return {
                                                                                                    changesMade: true,
                                                                                                    billToInfoVerified: false,
                                                                                                    ANETCustomerProfile: {
                                                                                                        ...prevState.ANETCustomerProfile,
                                                                                                        paymentProfiles: updatedPaymentProfiles
                                                                                                    }
                                                                                                }
                                                                                            })
                                                                                        }}
                                                                                        className={"state_select"}
                                                                                        options={this.props.states}
                                                                                    />, 'Card holder state'
                                                                                    )}
                                                                                    {buildRow("Country",
                                                                                        <Select
                                                                                            name={`BillToCountry${index+1}`}
                                                                                            isSearchable={true}
                                                                                            placeholder={"Please Select..."}
                                                                                            noOptionsMessage={() => "No option"}
                                                                                            value={ getLabel(val.billTo.country, this.props.countries) }
                                                                                            onChange={(e)=>{
                                                                                                this.setState( (prevState) => {
                                                                                                    const updatedPaymentProfiles = [...prevState.ANETCustomerProfile.paymentProfiles];
                                                                                                    updatedPaymentProfiles[index] = {
                                                                                                        ...updatedPaymentProfiles[index],
                                                                                                        billTo: {
                                                                                                            ...updatedPaymentProfiles[index].billTo,
                                                                                                            country: e.value
                                                                                                        }
                                                                                                    }
                                                                                                    
                                                                                                    return {
                                                                                                        changesMade: true,
                                                                                                        billToInfoVerified: false,
                                                                                                        ANETCustomerProfile: {
                                                                                                            ...prevState.ANETCustomerProfile,
                                                                                                            paymentProfiles: updatedPaymentProfiles
                                                                                                        }
                                                                                                    }
                                                                                                })
                                                                                            }}
                                                                                            className={"state_select"}
                                                                                            options={this.props.countries}
                                                                                        />, 'Card holder country'
                                                                                    )}
                                                                                    {buildRow("Zipcode",
                                                                                        <input className={"form-control"}
                                                                                            autoComplete={"off"}
                                                                                            type={"search"}
                                                                                            name={`BillToZipcode${index+1}`}
                                                                                            onChange={(e)=>{
                                                                                                this.setState( (prevState) => {
                                                                                                    const updatedPaymentProfiles = [...prevState.ANETCustomerProfile.paymentProfiles];
                                                                                                    updatedPaymentProfiles[index] = {
                                                                                                        ...updatedPaymentProfiles[index],
                                                                                                        billTo: {
                                                                                                            ...updatedPaymentProfiles[index].billTo,
                                                                                                            zip: e.target.value
                                                                                                        }
                                                                                                    }
                                                                                                    
                                                                                                    return {
                                                                                                        changesMade: true,
                                                                                                        billToInfoVerified: false,
                                                                                                        ANETCustomerProfile: {
                                                                                                            ...prevState.ANETCustomerProfile,
                                                                                                            paymentProfiles: updatedPaymentProfiles
                                                                                                        }
                                                                                                    }
                                                                                                })
                                                                                            }}
                                                                                            value={val.billTo.zip}
                                                                                        />
                                                                                    , 'Card holder zipcode'
                                                                                    )}
                                                                                    {buildRow("Card Number",
                                                                                        <input className={"form-control"}
                                                                                            autoComplete={"off"}
                                                                                            type={"search"}
                                                                                            name={`BillToCardNumber${index+1}`}
                                                                                            readOnly={true}
                                                                                            value={val.payment.creditCard.cardNumber}
                                                                                        />
                                                                                    , 'Credit Card Number'
                                                                                    )}
                                                                                    {buildRow("Card Type",
                                                                                        <input className={"form-control"}
                                                                                            autoComplete={"off"}
                                                                                            readOnly={true}
                                                                                            type={"search"}
                                                                                            name={`BillToCardType${index+1}`}
                                                                                            value={val.payment.creditCard.cardType === 'AmericanExpress' ? 'American Express' : val.payment.creditCard.cardType}
                                                                                        />
                                                                                    , 'Card Type'
                                                                                    )}
                                                                                    {buildRow("",
                                                                                        <div className="row">
                                                                                                <div className={!this.state.billToInfoVerified ? "col-12" : "d-none"}>
                                                                                                    <div className="btn btn-small btn-success w-100" onClick={() => this.verifyBillToInfo()}>
                                                                                                        Validate Bill To Info
                                                                                                    </div>
                                                                                                </div>
                                                                                                
                                                                                                <div className={
                                                                                                //bill to not verified
                                                                                                !this.state.billToInfoVerified ? "d-none" : 
                                                                                                //bill to verified, no card saved, show Add Card button
                                                                                                this.state.billToInfoVerified && !this.state.selectedFacility?.ANETCustomerProfileID && !this.state.ANETCustomerProfile.customerProfileId ? "col-12" :
                                                                                                //bill to verified, first time card added, do not show update
                                                                                                this.state.billToInfoVerified && !this.state.selectedFacility?.ANETCustomerProfileID && this.state.ANETCustomerProfile.customerProfileId ? "d-none" : 
                                                                                                //bill to verified, not first time
                                                                                                "col-12 col-md-6"}>
                                                                                                    <HostedForm 
                                                                                                        buttonText={this.state.selectedFacility?.ANETCustomerProfileID ? "Update Card" : "Add Card"}
                                                                                                        buttonClassName="btn-btn-small btn-success w-100"
                                                                                                        buttonStyle={{border: 'none', borderRadius: '0.25rem', padding: '0.45rem'}}
                                                                                                        formButtonText={"Save"}
                                                                                                        formHeaderText={"Card Information"}
                                                                                                        billingAddressOptions={{show: true, required: true}}
                                                                                                        // errorTextStyle={{display: 'none'}}
                                                                                                        authData={authData} 
                                                                                                        environment={aNetEnv}
                                                                                                        onSubmit={(e) => {
                                                                                                            this.handleBillToSubmit(e)
                                                                                                        }} 
                                                                                                    />
                                                                                                </div>
                                                                                            <div className={
                                                                                                this.state.billToInfoVerified && this.state.selectedFacility?.ANETCustomerProfileID ? "col-12 pt-1 pt-md-0 col-md-6 text-right" : "d-none"}>
                                                                                                <div className="btn btn-small btn-success w-100" onClick={() => this.updateInfoOnly()}>
                                                                                                    Update Info
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    );
                                                                })
                                                                }                                                                                                          
                                                        </div>
                                                    </div> */}


                                                    {/*Custom Fields*/}
                                                    {(this.props.pageCustomQuestions && this.props.pageCustomQuestions.length > 0) ?
                                                        <div className="card mb-2">
                                                            <div className="card-header verlag-bold"
                                                                 data-toggle="collapse"
                                                                 data-target="#customFieldCollapse"
                                                                 onClick={() => this.setState({customFieldArrowDown: !this.state.customFieldArrowDown})}
                                                                 role="button" aria-expanded="false"
                                                                 aria-controls="customFieldCollapse">
                                                                    <div className="row">
                                                                        <div className="col-10">
                                                                            <h4>
                                                                                Custom Data
                                                                            </h4>
                                                                        </div>
                                                                        <div className="col-2">
                                                                            {this.state.customFieldArrowDown ? (
                                                                            <AiOutlineUp className={'float-right'} size={30}
                                                                                        data-toggle="collapse"
                                                                                        data-target="#customFieldCollapse"
                                                                                        role="button" aria-expanded="false"
                                                                                        aria-controls="customFieldCollapse"/>) : (
                                                                            <AiOutlineDown className={'float-right'}
                                                                                        size={30} data-toggle="collapse"
                                                                                        data-target="#customFieldCollapse"
                                                                                        role="button"
                                                                                        aria-expanded="false"
                                                                                        aria-controls="customFieldCollapse"/>)}                                                             
                                                                        </div>
                                                                    </div>
                                                            </div>
                                                            <div className={"card-body collapse"}
                                                                 id={'customFieldCollapse'}>
                                                                <div className="row">
                                                                                                                                   
                                                                    {this.props.pageCustomQuestions.map((val, index) => {
                                                                        if(val.Options.isActive){
                                                                            if (index % 2 === 0){
                                                                                return (
                                                                                    <div className="col-12 col-lg-6">
                                                                                        <div className="form-group row">
                                                                                            <GetField 
                                                                                            val={val} 
                                                                                            selectedItem={this.state.selectedFacility ? this.state.selectedFacility : {}} 
                                                                                            handleChange={(e) => {
                                                                                                this.setState({ selectedFacility: e, changesMade: true})
                                                                                            }}  
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            } else if(index % 2 === 1){
                                                                                return (
                                                                                    <div className="col-12 col-lg-6">
                                                                                        <div className="form-group row">
                                                                                            <GetField 
                                                                                            val={val} 
                                                                                            selectedItem={this.state.selectedFacility ? this.state.selectedFacility : {}} 
                                                                                            handleChange={(e) => {
                                                                                                this.setState({ selectedFacility: e, changesMade: true})
                                                                                            }}  
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        }
                                                                    })}
                                                                              
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="invisible"></div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer" style={{backgroundColor: 'transparent', borderTop: 'none'}}>
                                        <button className={"btn btn-primary "} onClick={() => this.onSubmit()} disabled={this.state.isSubmitting}>Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </React.Fragment>
        )
    }
}

